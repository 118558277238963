// import { createTheme } from "@mui/material";

export const useStyles = () => ({
  main: {
    cursor : "pointer",
    display: "flex", 
    alignItems: "center",
  },
  item : {
    height: "100%",
    "& span" : {
      cursor : "pointer",
      fontWeight: "strong",
      fontSize: "1.2rem",
    },  
  },
});
