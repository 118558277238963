import { useContext, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { useStyles } from "./AboutUs.style";
import { CardPersonal } from "shared/components/card-personal";
import { useTheme } from "@mui/material";
import { equipe } from "content/about-us";
import { MenuVisibilityContext } from "shared/context";
import Carousel from "react-material-ui-carousel";
import { images_team } from "assets/img/equipe_at_work";

export const AboutUs: React.FC = () => {
  const styles = useStyles();
  const theme = useTheme();
  const menuProperties = useContext(MenuVisibilityContext);

  useEffect(() => {
    if (false == menuProperties.isMenuVisible) {
      menuProperties.toggleMenuVisibility();
    }
  }, []);

  return (
    <Box>
      <Box sx={{ ...styles.texts }}>
        <Box sx={styles.title}>
          <Typography variant="h2">QUEM SOMOS</Typography>
        </Box>
        <Box sx={styles.subtitle}>
          <Typography>Conheça a equipe responsável pelo projeto.</Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "row",
          width: "100%",
          ["@media (max-width:600px)"]: { flexDirection: "column" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: "50%",
            alignItems: "center",
            flexDirection: "column",
            padding: "2rem",
            ["@media (max-width:600px)"]: { width: "100%" },
          }}
        >
          <Typography
            sx={{
              textAlign: "center",
              fontWeight: "bold",
              fontFamily: "Open Sans, sans-serif",
              fontSize: "1.5rem",
            }}
          >
            SOBRE A PLATAFORMA
          </Typography>
          <Typography
            sx={{
              marginTop: "1rem",
              textAlign: "justify",
              fontSize: "22px",
              fontFamily: "Open Sans, sans-serif",
            }}
          >
            O programa iniciou-se em junho de 2021 e dois projetos estão sendo
            executados: 1. Cultura, Inovação e Inclusão Social no Ceará e 2.
            Tecnologia e Inovação para o Patrimônio Cultural do Ceará. O
            primeiro tem como objetivo principal promover a Inclusão Social por
            meio da Cultura; o segundo foca na preservação e difusão do
            patrimônio cultural do Ceará por meio da digitalização de
            equipamentos culturais e acervos; possibilitando acesso virtual
            interativo, a teatros, museus, bibliotecas, escolas, esculturas,
            livros, revistas e jornais.
          </Typography>
        </Box>
        <Box
          sx={{
            width: "50%",
            display: "flex",
            justifyContent: "center",
            overflow: "hidden",
            padding: "1.5rem",
            ["@media (max-width:600px)"]: { width: "100%" },
          }}
        >
          <Carousel sx={{ width: "100%" }}>
            <Box
              sx={{
                display: "flex",
                alignContent: "center",
                justifyContent: "flex-start",
                flexDirection: "column",
              }}
            >
              <img src={images_team.image4} alt="logo cientista chefe"></img>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignContent: "center",
                justifyContent: "flex-start",
                flexDirection: "column",
              }}
            >
              <img src={images_team.image1} alt="logo cientista chefe"></img>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignContent: "center",
                justifyContent: "flex-start",
                flexDirection: "column",
              }}
            >
              <img src={images_team.image2} alt="logo cientista chefe"></img>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignContent: "center",
                justifyContent: "flex-start",
                flexDirection: "column",
              }}
            >
              <img src={images_team.image3} alt="logo cientista chefe"></img>
            </Box>
          </Carousel>
        </Box>
      </Box>
      <Box sx={styles.cards}>
        <Box> 
        {equipe.map((membro) => (
          <Box>
            <CardPersonal
              name={membro.name}
              cargo={membro.cargo}
              image={membro.image}
              lattes={membro.lattes}
            />
          </Box>
        ))}
        </Box>
      </Box>
    </Box>
  );
};
