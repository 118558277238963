import React from "react";
import { useState } from "react";
import { useStyles } from "./CardHowToFind.style";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ExploreOutlinedIcon from "@mui/icons-material/ExploreOutlined";
import AccessibilityNewOutlinedIcon from "@mui/icons-material/AccessibilityNewOutlined";
import AlternateEmailOutlinedIcon from "@mui/icons-material/AlternateEmailOutlined";
import ThumbUpAltOutlinedIcon from "@mui/icons-material/ThumbUpAltOutlined";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import MailOutlinedIcon from "@mui/icons-material/MailOutlined";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";
import TwitterIcon from "@mui/icons-material/Twitter";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import Link from "@mui/material/Link";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";

interface SocialMedia {
  Instagram?: string;
  Facebook?: string;
  YouTube?: string;
  TikTok?: string;
  Twitter?: string;
}

interface Address {
  name: string;
  link: string;
}

interface Contacts {
  phoneNumber?: string;
  whatsApp?: string;
  email?: string;
}
interface guidedTour {
  name: string;
  link: string;
}

interface Chegar {
  address: Address;
  guidedTour?: string;
  accessibility?: string;
  contacts?: Contacts;
  openingHours?: string;
  socialMedia?: SocialMedia;
}

export const CardHowToFind: React.FC<Chegar> = ({
  address,
  guidedTour,
  accessibility,
  contacts,
  openingHours,
  socialMedia,
}: Chegar) => {
  const styles = useStyles();
  const [openAddress, setOpenAddress] = useState(false);
  const [openOpeningHours, setOpenOpeningHours] = useState(false);
  const [openVisit, setOpenVisit] = useState(false);
  const [openAccessibility, setOpenAccessibility] = useState(false);
  const [openInfo, setOpenInfo] = useState(false);
  const [openSocials, setOpenSocials] = useState(false);

  const handleClickAddress = () => {
    setOpenAddress(!openAddress);
  };
  const handleClickOpeningHours = () => {
    setOpenOpeningHours(!openOpeningHours);
  };
  const handleClickVisit = () => {
    setOpenVisit(!openVisit);
  };
  const handleClickAccessibility = () => {
    setOpenAccessibility(!openAccessibility);
  };
  const handleClickInfo = () => {
    setOpenInfo(!openInfo);
  };
  const handleClickSocials = () => {
    setOpenSocials(!openSocials);
  };

  return (
    <List
      sx={{ width: "100%", bgcolor: "#eae6e4" }}
      component="nav"
      aria-labelledby="nested-list-subheader"
    >
      <ListItemButton onClick={handleClickAddress}>
        <ListItemIcon>
          <LocationOnOutlinedIcon />
        </ListItemIcon>
        <ListItemText primary="Endereço" />
        {openAddress ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={openAddress} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon></ListItemIcon>
            <Link href={address.link} target="_blank" underline="none">
              <ListItemText
                sx={{
                  color: "#000000de",
                  ":hover": { color: "#2a9d8f" },
                }}
                primary={address.name}
              />
            </Link>
          </ListItemButton>
        </List>
      </Collapse>
      {/* Horários de Funcionamento */}
      {openingHours ? (
        <>
          <ListItemButton onClick={handleClickOpeningHours}>
            <ListItemIcon>
              <CalendarMonthOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Horários de Funcionamento" />
            {openOpeningHours ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={openOpeningHours} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon></ListItemIcon>
                <ListItemText primary={openingHours} />
              </ListItemButton>
            </List>
          </Collapse>
        </>
      ) : (
        <></>
      )}

      {/* Visitas Guiadas */}
      {guidedTour ? (
        <>
          <ListItemButton onClick={handleClickVisit}>
            <ListItemIcon>
              <ExploreOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Visitas Guiadas" />
            {openVisit ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={openVisit} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon></ListItemIcon>
                <ListItemText primary={guidedTour} />
              </ListItemButton>
            </List>
          </Collapse>
        </>
      ) : (
        <></>
      )}

      {/* Acessibilidade */}
      {accessibility ? (
        <>
          <ListItemButton onClick={handleClickAccessibility}>
            <ListItemIcon>
              <AccessibilityNewOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Acessibilidade" />
            {openAccessibility ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={openAccessibility} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon></ListItemIcon>
                <ListItemText primary={accessibility} />
              </ListItemButton>
            </List>
          </Collapse>
        </>
      ) : (
        <></>
      )}

      <ListItemButton onClick={handleClickInfo}>
        <ListItemIcon>
          <AlternateEmailOutlinedIcon />
        </ListItemIcon>
        <ListItemText primary="Contatos" />
        {openInfo ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      {/* Telefone */}
      {contacts?.phoneNumber ? (
        <Collapse in={openInfo} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <CallOutlinedIcon />
              </ListItemIcon>
              <ListItemText
                primary={"Telefone para contato: " + contacts?.phoneNumber}
              />
            </ListItemButton>
          </List>
        </Collapse>
      ) : (
        <></>
      )}
      {/* Email */}
      {contacts?.email ? (
        <Collapse in={openInfo} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <MailOutlinedIcon />
              </ListItemIcon>
              <ListItemText
                primary={"Email para contato: " + contacts?.email}
              />
            </ListItemButton>
          </List>
        </Collapse>
      ) : (
        <></>
      )}
      {/* WhatsApp */}
      {contacts?.whatsApp ? (
        <Collapse in={openInfo} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <WhatsAppIcon />
              </ListItemIcon>
              <ListItemText
                primary={"WhatsApp para contato: " + contacts?.whatsApp}
              />
            </ListItemButton>
          </List>
        </Collapse>
      ) : (
        <></>
      )}

      <ListItemButton onClick={handleClickSocials}>
        <ListItemIcon>
          <ThumbUpAltOutlinedIcon />
        </ListItemIcon>
        <ListItemText primary="Redes Sociais" />
        {openSocials ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      {/* Instagram */}
      {socialMedia?.Instagram ? (
        <Collapse in={openSocials} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <InstagramIcon />
              </ListItemIcon>
              <ListItemText
                primary={
                  <ListItemIcon>
                    <Link
                      sx={{
                        color: "#000000de",
                        ":hover": { color: "#2a9d8f" },
                      }}
                      href={socialMedia?.Instagram}
                      target="_blank"
                      underline="none"
                    >
                      Instagram
                    </Link>
                  </ListItemIcon>
                }
              />
            </ListItemButton>
          </List>
        </Collapse>
      ) : (
        <></>
      )}
      {/* Facebook */}
      {socialMedia?.Facebook ? (
        <Collapse in={openSocials} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <FacebookIcon />
              </ListItemIcon>
              <ListItemText
                primary={
                  <ListItemIcon>
                    <Link
                      sx={{
                        color: "#000000de",
                        ":hover": { color: "#2a9d8f" },
                      }}
                      href={socialMedia?.Facebook}
                      target="_blank"
                      underline="none"
                    >
                      Facebook
                    </Link>
                  </ListItemIcon>
                }
              />
            </ListItemButton>
          </List>
        </Collapse>
      ) : (
        <></>
      )}
      {/* YouTube */}
      {socialMedia?.YouTube ? (
        <Collapse in={openSocials} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <YouTubeIcon />
              </ListItemIcon>
              <ListItemText
                primary={
                  <ListItemIcon>
                    <Link
                      sx={{
                        color: "#000000de",
                        ":hover": { color: "#2a9d8f" },
                      }}
                      href={socialMedia?.YouTube}
                      target="_blank"
                      underline="none"
                    >
                      YouTube
                    </Link>
                  </ListItemIcon>
                }
              />
            </ListItemButton>
          </List>
        </Collapse>
      ) : (
        <></>
      )}

      {/* Twitter */}
      {socialMedia?.Twitter ? (
        <Collapse in={openSocials} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <TwitterIcon />
              </ListItemIcon>
              <ListItemText
                primary={
                  <ListItemIcon>
                    <Link
                    sx={{
                      color: "#000000de",
                      ":hover": { color: "#2a9d8f" },
                    }}
                      href={socialMedia?.Twitter}
                      target="_blank"
                      underline="none"
                    >
                      Twitter
                    </Link>
                  </ListItemIcon>
                }
              />
            </ListItemButton>
          </List>
        </Collapse>
      ) : (
        <></>
      )}
    </List>
  );
};
