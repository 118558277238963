import { Box } from "@mui/material";
import { MiniMenu } from "shared/components/menu-monument/MiniMenu";
import { TourVirtual } from "shared/components/tour-virtual";
import { History } from "./components";
import { useStyles } from "./escola.style";
import { Gallery } from "shared/components/gallery/Gallery";
import { CardHowToFind } from "shared/components/card-how-to-find";
import homeGallery from "./escola-gallery";

export const ESCOLA: React.FC = () => {
    const styles = useStyles();
    return(
    <Box sx={styles.container}>
      <Box sx={styles.tour}>
        <TourVirtual src="https://my.matterport.com/show/?m=s5TbziPVeU7" />        
        <Box sx={styles.miniMenu}>
          <MiniMenu
            title="Escola de Gastronomia Social Ivens Dias Branco"
            history={<History />}
            gallery={<Gallery images={homeGallery} />}
            info={
              <CardHowToFind 
              address={{
                name:"R. Manuel Dias Branco, 80 – Cais do Porto, Fortaleza – CE, 60180-635",
                link:"https://goo.gl/maps/bnEeAFTJ1MH7Xjhu5" 
              }}
              //guidedTour="Email para agendamento: visitas.bece@idm.org.br"
              accessibility="Possui alternativas de acessibilidade."
              openingHours="Segunda à sexta: 9h às 17h." 
              contacts={{
                phoneNumber:"+55 (85) 3263.4596",
                email:"ascom.gastronomiasocial@idm.org.br"
              }}
              socialMedia={{
                Instagram:"https://www.instagram.com/escolagastronomiasocial/",
                Facebook:"https://www.facebook.com/escolagastronomiasocial",
                YouTube:"https://www.youtube.com/escoladegastronomiasocialivensdiasbranco",
                TikTok:"https://www.tiktok.com/@escolagastronomiasocial",
              }}
              />
          }
          />
        </Box>
      </Box>
    </Box>
    );
};