import { Box } from "@mui/material";
import { MiniMenu } from "shared/components/menu-monument/MiniMenu";
import { TourVirtual } from "shared/components/tour-virtual";
import { History } from "./components";
import { useStyles } from "./CINE.style";
import { CardHowToFind } from "shared/components/card-how-to-find";
import homeGallery from "./CINEgallery";
import { Gallery } from "shared/components/gallery/Gallery";

export const CINE: React.FC = () => {
    const styles = useStyles();
    return(
    <Box sx={styles.container}>
      <Box sx={styles.tour}>
        <TourVirtual src="https://repositoriodocumentace.online/cineteatro-sao-luiz/index.htm" />        
        <Box sx={styles.miniMenu}>
          <MiniMenu
            title="Cineteatro São Luiz"
            history={<History />}
            gallery={<Gallery images={homeGallery} />}
            info={
              <CardHowToFind 
              address={{
                name:"R. Major Facundo, 500 - Centro, Fortaleza - CE, 60025-130",
                link:"https://maps.app.goo.gl/GXNLRnXdeivgs9pU7" 
              }}
              guidedTour="Email para agendamento: osaoluizenosso@idm.org.br"
              accessibility="Possui alternativas de acessibilidade."
              openingHours="Terça a Sexta: 9h30 às 18h |
              Sábado: 9h30 às 17h" 
              contacts={{
                phoneNumber:" (85) 3252.4138",
                email:"osaoluizenosso@idm.org.br"
              }}
              socialMedia={{
                Instagram:"https://www.instagram.com/cineteatrosaoluiz/",
                Facebook:"https://www.facebook.com/cineteatrosaoluiz",
                YouTube:"https://www.youtube.com/c/CineteatroS%C3%A3oLuizFortaleza",
                Twitter:"https://twitter.com/Cineteatro_SL0"
              }}
              />
          }
          />
        </Box>
      </Box>
    </Box>
    );
};