import { Box } from "@mui/material";
import { MiniMenu } from "shared/components/menu-monument/MiniMenu";
import { TourVirtual } from "shared/components/tour-virtual";
import { History } from "./components";
import { useStyles } from "./MIS.style";
import { Gallery } from "shared/components/gallery/Gallery";
import homeGallery from "./MISgallery";
import { CardHowToFind } from "shared/components/card-how-to-find";

export const MIS: React.FC = () => {
  const styles = useStyles();

  return (
    <Box sx={styles.container}>
      <Box sx={styles.tour}>
        <TourVirtual src="https://repositoriodocumentace.online/museu-da-imagem-e-do-som-do-ceara/index.htm" />
        <Box sx={styles.miniMenu}>
          <MiniMenu
            title="Museu da Imagem e do Som"
            history={<History />}
            gallery={<Gallery images={homeGallery} />}
            info={
              <CardHowToFind
                address={{
                  name: "Av. Barão de Studart, 410 - Meireles, Fortaleza - CE, 60120-000",
                  link: "https://goo.gl/maps/c22wHahhMavetW2S8",
                }}
                openingHours="Terça à quinta: 10h - 18h | Sexta à domingo: 13h - 20h (Gratuito) | Segunda: fechado"
                contacts={{
                  phoneNumber: "(85) 3101-1207",
                }}
                socialMedia={{
                  Instagram: "https://www.instagram.com/mis_ceara/",
                }}
              />
            }
          />
        </Box>
      </Box>
    </Box>
  );
};
