import { createTheme } from "@mui/material";

const theme = createTheme();

export const useStyles = () => ({
  background: {
    borderBottom: "1px solid #D3D3D3",
    "& button:hover": {
      backgroundColor: "#DDDDDD",
      color: "#FFFFFF !important",
    },
    // "& button:active": {
    //   backgroundColor: "#E97651",
    //   color: "#FFFFFF !important",
    // },
    // "& button:focus": {
    //   backgroundColor: "#E97651",
    //   color: "#FFFFFF !important",
    // },
    // "& button.Mui-selected": {
    // backgroundColor: "#E97651",
    // borderBottom: "2px solid black",
    // color: "#FFFFFF !important",
    // },
  },
  link: {
    color: "#000000 !important",
    fontWeight: "500 !important",
  },
  container: {
    "& > :nth-child(4)": {
      width: "100%",
    },
  },
});
