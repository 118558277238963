import { Box } from "@mui/material";
import { GalleryItem } from "./components/GalleryItem/GalleryItem";
import { useStyles } from "./Gallery.style";
import { useState } from "react";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CloseIcon from '@mui/icons-material/Close';

interface GalleryPros {
  images: string[];
}

export const Gallery: React.FC<GalleryPros> = ({ images }) => {
  const styles = useStyles();

  const [slideNumber, setSlideNumber] = useState(0)
  const [openModal, setOpenModal] = useState(false)

  const handleOpenModal = (index: number) => {
    setSlideNumber(index)
    setOpenModal(true)
  }

  const handleCloseModal = () => {
    setOpenModal(false)
  }

  const prevSlide = () => {
    slideNumber === 0
      ? setSlideNumber(images.length - 1)
      : setSlideNumber(slideNumber - 1)
  }

  const nextSlide = () => {
    slideNumber + 1 === images.length
      ? setSlideNumber(0)
      : setSlideNumber(slideNumber + 1)
  }

  return (
    <Box>
      {openModal &&
        <Box sx={styles.modal}>
          <ArrowBackIosNewIcon sx={[styles.icons, styles.leftArrow]} onClick={prevSlide} />
          <ArrowForwardIosIcon sx={[styles.icons, styles.rightArrow]} onClick={nextSlide} />
          <CloseIcon sx={[styles.icons, styles.close]} onClick={handleCloseModal} />
          <Box sx={styles.fullscreen}>
            <img src={images[slideNumber]} alt='' />
          </Box>
        </Box>
      }

      <Box sx={styles.root}>
        {images.map((item, index) => {
          return <GalleryItem
            key={index}
            onClick={() => handleOpenModal(index)} image={item} />;
        })}
      </Box>
    </Box>
  );
};
