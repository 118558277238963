import img01 from "assets/img/gallery/CINE/img01.JPG";
import img02 from "assets/img/gallery/CINE/img02.JPG";
import img03 from "assets/img/gallery/CINE/img03.JPG";
import img04 from "assets/img/gallery/CINE/img04.JPG";
import img05 from "assets/img/gallery/CINE/img05.JPG";
import img06 from "assets/img/gallery/CINE/img06.JPG";
import img07 from "assets/img/gallery/CINE/img07.JPG";
import img08 from "assets/img/gallery/CINE/img08.JPG";
import img09 from "assets/img/gallery/CINE/img09.JPG";
import img10 from "assets/img/gallery/CINE/img10.JPG";
import img11 from "assets/img/gallery/CINE/img11.JPG";
import img12 from "assets/img/gallery/CINE/img12.JPG";
import img13 from "assets/img/gallery/CINE/img13.JPG";
import img14 from "assets/img/gallery/CINE/img14.JPG";
const homeGallery = [
    img01,
    img02,
    img03,
    img04,
    img05,
    img06,
    img07,
    img08,
    img09,
    img10,
    img11,
    img12,
    img13,
    img14,
]
export default homeGallery;