// import { makeStyles } from "@mui/styles";

export const useStyles = () => ({
  texts: {
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
    flexDirection: "column",
    padding: "120px 0px",
    textAlign: "center"
    // backgroundColor: "#5A2E85",
    // color: "#FFF",
  },
  title: {
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
    margin: "15px",
    "& p": {
      fontSize: "40px",
      padding: "0px 5px",
      fontWeight: "lighter",
    },
  },
  subtitle: {
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
    alignSelf: "center",
    maxWidth: "600px",
    margin: "0px 10%",
    "& p": {
      fontSize: "20px",
      fontWeight: "lighter",
      textAlign: "center",
    },
  },
  cards: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    height: "100%",
    backgroundColor: "#EAE6E4",
    padding: "16px 0px",
    "& > div": {
      display: "grid",
      gridTemplateColumns: "repeat(4, 1fr)",
      gap: "40px",
      ["@media (max-width: 1024px)"]: { 
        gridTemplateColumns: "repeat(3, 1fr)", 
      },
      ["@media (max-width: 768px)"]: { 
        gridTemplateColumns: "repeat(2, 1fr)", 
      },
      ["@media (max-width: 500px)"]: { 
        gridTemplateColumns: "repeat(1, 1fr)", 
      },
    }
  },
});
