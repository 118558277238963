import { useRef, useEffect, useState, useContext } from "react";
import Box from "@mui/material/Box";
import { useMediaQuery } from "@mui/material";
import { ImageList, ImageListItem } from "@mui/material/";
import { useStyles } from "./Home.style";
import { Typography } from "@mui/material";
import logoCientistaChefe from "assets/img/logoCientistaChefe.png";
import { images } from "assets/img/cards";
import { Link, useNavigate } from "react-router-dom";
import video from "assets/videos/background.mp4";
import { MenuVisibilityContext } from "shared/context";
import { useTheme } from "@mui/material/styles";
import logoInicial from "assets/img/logoInicial.svg";
import { motion } from "framer-motion";
import { CardNews } from "shared/components/card-news";
import template_leao from "assets/img/template_leao.png";
import template_linhabranca01 from "assets/img/template_linhabranca01.png";
import template_linhabranca02 from "assets/img/template_linhabranca02.png";
import template_linhabranca03 from "assets/img/template_linhabranca03.png";

function srcset(image: string, size: number, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${
      size * rows
    }&fit=crop&auto=format&dpr=2 2x`,
  };
}

const itemData = [
  {
    img: images.image1,
    link: "tja",
    title: "Teatro José de Alencar",
    rows: 2,
    cols: 2,
  },
  {
    img: images.image7,
    link: "mis",
    title: "Museu da Imagem e do Som",
    rows: 1,
    cols: 2,
  },
  {
    img: images.image8,
    link: "eaotps",
    title: "Escola de Artes e Ofícios Thomaz Pompeu Sobrinho",
    rows: 1,
    cols: 1,
  },
  {
    img: images.image3,
    link: "bece",
    title: "Biblioteca Pública Estadual do Ceará (BECE)",
    rows: 1,
    cols: 1,
  },
  // { img: images.image5, title: "Centro Dragão do Mar de Arte e Cultura",  rows : 1, cols : 1 },
  // { img: images.image6, title: "Escola de Gastronomia Social Ivens Dias Branco",  rows : 1, cols : 1 },
  // { img: images.image2, link: "sobrado", title: "Sobrado Dr. José Lourenço", rows: 1, cols: 1 },
  // { img: images.image4, title: "Cine São Luiz", rows: 1, cols: 1 },
];

const newsData = [
  {
    date: "20 de junho de 2023",
    title:
      "Pesquisa da UFC sobre documentação histórica do Theatro José de Alencar é premiada em congresso internacional",
    abstract:
      "Pesquisa da Universidade Federal do Ceará sobre a documentação histórica do Theatro José de Alencar, conduzida pela equipe do Laboratório de Reabilitação e Durabilidade das Construções (LAREB), do Campus da Universidade Federal do Ceará em Russas, recebeu a premiação Vitervo O’Reilly, de melhor artigo no XIX Congresso Internacional sobre Patologia e Reabilitação das Construções (CINPAR 2023).",
    link: "https://www.ufc.br/noticias/17905-pesquisa-da-ufc-sobre-documentacao-historica-do-theatro-jose-de-alencar-e-premiada-em-congresso-internacional",
  },
  {
    date: "30 de maio de 2023",
    title:
      "Theatro José de Alencar é o primeiro do Brasil a ganhar modelo em 3D de alta precisão; projeto auxilia na manutenção e nos restauros do patrimônio",
    abstract:
      "Projeto desenvolvido por alunos e professores da UFC é a representação mais precisa já elaborada para equipamentos históricos do Ceará. Digitalização já permite visitas virtuais ao teatro",
    link: "https://agencia.ufc.br/theatro-jose-de-alencar-e-o-primeiro-do-brasil-a-ganhar-modelo-em-3d-de-alta-precisao-projeto-auxilia-na-manutencao-e-nos-restauros-do-patrimonio/",
  },
  {
    date: "19 de Abril de 2023",
    title:
      "Primeiro equipamento cultural digitalizado no estado do Ceará é tema de publicação em revista internacional. O artigo faz parte do projeto Cientista Chefe da Cultura.",
    abstract:
      "A casa José de Alencar, localizada no bairro Messejana, na cidade de Fortaleza-CE, que pertenceu ao próprio escritor, um dos grandes nomes da literatura brasileira, foi a primeira edificação histórica do estado do Ceará a ser digitalizada com tecnologia de alta resolução. O artigo aborda desde a técnica de aquisição de dados em campo através de um laser scaner até o processo de modelagem em BIM, utilizando o software Revit da Autodesk. Além disso, traz um enfoque na avaliação de danos causados na edificação ao longo do tempo e em como as ferramentas HBIM podem ser utilizadas na avaliação de anomalias em construções patrimoniais.",
    link: "http://www.campusrussas.ufc.br/noticia.php?v=1459&t=Primeiro-equipamento-cultural-digitalizado-no-estado-do-Cear%C3%A1-%C3%A9-tema-de-publica%C3%A7%C3%A3o-em-revista-internacional.-O-artigo-faz-parte-do-projeto-Cientista-Chefe-da-Cultura",
  },
  {
    date: "03 de janeiro de 2023",
    title:
      "Plataforma Documenta CE disponibiliza primeira visita virtual a equipamentos culturais do Ceará; UFC é responsável pelo projeto",
    abstract:
      "O Programa Cientista-Chefe Cultura disponibilizou a plataforma digital Documenta CE, que possibilita a visita virtual a equipamentos culturais do Ceará. O primeiro tour virtual é no Theatro José de Alencar. A UFC integra o Programa Cientista-Chefe Cultura e é a instituição responsável por realizar a digitalização desses equipamentos.",
    link: "https://www.ufc.br/noticias/17492-plataforma-documenta-ce-disponibiliza-primeira-visita-virtual-a-equipamentos-culturais-do-ceara-ufc-e-responsavel-pelo-projeto",
  },
];

export const Home: React.FC = () => {
  const smDown = useMediaQuery(useTheme().breakpoints.down("sm"));
  const mdDown = useMediaQuery(useTheme().breakpoints.down("md"));
  const lgDown = useMediaQuery(useTheme().breakpoints.down("lg"));

  const cols = smDown ? 1 : mdDown ? 2 : lgDown ? 3 : 4;
  const rowHeight = smDown ? 250 : mdDown ? 300 : lgDown ? 350 : 400;
  const styles = useStyles();
  const navigate = useNavigate();

  return (
    <>
      <Box component="div" sx={styles.background}>
        <video disablePictureInPicture={true} src={video} autoPlay loop muted />
        <Box
          sx={{
            ...styles.title,
          }}
        >
          <img
            src={logoInicial}
            style={styles.logoInicial}
            alt="logo Monumenta"
          />
        </Box>
        <Box sx={styles.imggradienttop} />
        <Box sx={styles.button}>
          <motion.div
            animate={{ y: [-5, 5, -5] }}
            transition={{
              repeat: Infinity,
            }}
          >
            <a href="#main">
              <svg
                fill="none"
                height="24"
                stroke-width="1.5"
                viewBox="0 0 24 24"
                width="24"
              >
                <path
                  d="M6 9L12 15L18 9"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </a>
          </motion.div>
        </Box>
      </Box>
      <Box
        id="main"
        sx={{
          display: "flex",
          position: "relative",
          overflow: "hidden",
          flexDirection: "row",
          width: "100%",
          height: "100vh",
          backgroundColor: "#2a9d8f",
          [useTheme().breakpoints.down("md")]: {
            flexDirection: "column",
            height: "100%",
          },
        }}
      >
        <Box
          component="div"
          sx={{
            position: "absolute",
            left: "-10rem",
            width: "100%",
            height: "100%",
            filter: "opacity(0.3)",
            backgroundRepeat: "no-repeat",
            backgroundSize: "30% auto",
            backgroundImage: `url(${template_linhabranca01})`,
            [useTheme().breakpoints.down("md")]: { left: "0rem", top: "0rem" },
          }}
        />
        <Box
          component="div"
          sx={{
            position: "absolute",
            left: "-10rem",
            top: "30rem",
            width: "100%",
            height: "100%",
            filter: "opacity(0.3)",
            backgroundRepeat: "no-repeat",
            backgroundSize: "30% auto",
            backgroundImage: `url(${template_linhabranca02})`,
          }}
        />
        <Box
          component="div"
          sx={{
            position: "absolute",
            left: "10rem",
            top: "-15rem",
            width: "100%",
            height: "100%",
            filter: "opacity(0.3)",
            backgroundRepeat: "no-repeat",
            backgroundSize: "30% auto",
            backgroundImage: `url(${template_linhabranca03})`,
          }}
        />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            "& span": { fontWeight: "bold" },
            "& span.fontemaior": { fontSize: "1.5em" },
          }}
        >
          <Typography
            padding="5rem"
            variant="h3"
            color="white"
            sx={{
              [useTheme().breakpoints.down("md")]: {
                fontSize: "2.0rem",
                padding: "3rem",
              },
            }}
          >
            A plataforma <span>Documenta</span> é um dos resultados do Programa{" "}
            <span className="fontemaior">Cientista Chefe da Cultura.</span>
          </Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography
            fontSize="1.5rem"
            color="white"
            sx={{
              [useTheme().breakpoints.down("md")]: {
                fontSize: "1.4rem",
                paddingX: "3rem",
              },
            }}
          >
            Aqui você vai poder conhecer um pouco mais sobre o patrimônio
            cultural do Estado do Ceará, através de visitas virtuais,
            informações históricas, modelos tridimensionais, imagens e muito
            mais.
          </Typography>
        </Box>
        <Box
          component="img"
          sx={{
            height: "100%",
            width: "100vw",
            // maxHeight: { xs: 233, md: 167 },
            // maxWidth: { xs: 350, md: 250 },
          }}
          alt="The house from the offer."
          src={template_leao}
        />
      </Box>
      <Box
        sx={{
          margin: "2rem",
          marginTop: "5rem",
          [useTheme().breakpoints.down("md")]: { marginTop: "1rem" },
        }}
      >
        <Typography
          variant="h5"
          sx={{ fontWeight: "bold", textAlign: "center", padding: "1rem" }}
        >
          Conheça alguns dos principais equipamentos culturais e patrimônios do
          Ceará
        </Typography>
        <ImageList
          sx={{ width: "100%", height: "auto" }}
          variant="quilted"
          gap={20}
          cols={cols}
          rowHeight={rowHeight}
        >
          {itemData.map((item) => (
            <ImageListItem
              sx={styles.imgLstContainer}
              key={item.img}
              cols={cols !== 1 ? item.cols : 1}
              rows={cols !== 1 ? item.rows : 1}
              onClick={() => {
                if (item.link) navigate(`../monument/${item.link}`);
              }}
            >
              <img
                {...srcset(item.img, 200, item.rows, item.cols)}
                alt={item.title}
                loading="lazy"
              />
              <Box sx={styles.imggradientbottom} />
              <Typography sx={styles.imgCaption}>{item.title}</Typography>
              {!item.link ? (
                <Box sx={styles.imgCaptionSoon}>
                  <Typography variant="h6">EM BREVE</Typography>
                </Box>
              ) : (
                ""
              )}
            </ImageListItem>
          ))}
        </ImageList>
        <Typography
          variant="h5"
          sx={{
            ...styles.link,
          }}
        >
          <Link to="/monumentlist">veja todos</Link>
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          marginBottom: "2rem",
        }}
      >
        <Typography
          variant="h5"
          sx={{
            fontWeight: "bold",
            textAlign: "center",
            padding: "1rem",
          }}
        >
          Veja as últimas notícias sobre o projeto:
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
            [useTheme().breakpoints.down("md")]: {
              flexDirection: "column",
              alignItems: "center",
            },
          }}
        >
          <CardNews
            date={newsData[0].date}
            title={newsData[0].title}
            abstract={newsData[0].abstract}
            link={newsData[0].link}
          />
          <CardNews
            date={newsData[1].date}
            title={newsData[1].title}
            abstract={newsData[1].abstract}
            link={newsData[1].link}
          />
          <CardNews
            date={newsData[2].date}
            title={newsData[2].title}
            abstract={newsData[2].abstract}
            link={newsData[2].link}
          />
          <CardNews
            date={newsData[3].date}
            title={newsData[3].title}
            abstract={newsData[3].abstract}
            link={newsData[3].link}
          />
        </Box>
      </Box>
    </>
  );
};
