import { Home } from "pages/main-page";
import { TJA } from "pages/monument/tja";
import { SOBRADO } from "pages/monument/sobrado";
import { BECE } from "pages/monument/bece";
import { MIS } from "pages/monument/mis";
import { MonumentList } from "pages/monument-list";
import { AboutUs } from "pages/about-us";
import { EAOTPS } from "pages/monument/eaotps";
import { ESCOLA } from "pages/monument/escola-gastronomia";
import { News } from "pages/news";
import { PINACOTECA } from "pages/monument/pinacoteca";
import { CINE } from "pages/monument/cine-sao-luiz";
import { IGREJA } from "pages/monument/igreja-assunção";

export const links = [
  {
    path: "/",
    page: <Home />,
  },
  {
    path: "/monumentlist/",
    page: <MonumentList />,
  },
  {
    path: "/monument/TJA",
    page: <TJA />,
  },
  {
    path: "/monument/SOBRADO",
    page: <SOBRADO />,
  },
  {
    path: "/monument/MIS",
    page: <MIS />,
  },
  {
    path: "/monument/EAOTPS",
    page: <EAOTPS />,
  },
  {
    path: "/monument/BECE",
    page: <BECE />,
  },
  {
    path: "/monument/escola-gastronomia",
    page: <ESCOLA />,
  },
  {
    path: "/about-us/",
    page: <AboutUs />,
  },
  {
    path: "/news",
    page: <News />,
  },
  {
    path: "/monument/pinacoteca",
    page: <PINACOTECA />,
  },
  {
    path: "/monument/cine",
    page: <CINE />,
  },
  {
    path: "/monument/igreja-assuncao",
    page: <IGREJA />,
  },
];
