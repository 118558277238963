import { Box } from "@mui/material";
import { MiniMenu } from "shared/components/menu-monument/MiniMenu";
import { TourVirtual } from "shared/components/tour-virtual";
import { History } from "./components";
import { useStyles } from "./EAOTPS.style";
import { Gallery } from "shared/components/gallery/Gallery";
import homeGallery from "./EAOTPSgallery";
import { CardHowToFind } from "shared/components/card-how-to-find";

export const EAOTPS: React.FC = () => {
  const styles = useStyles();

  return (
    <Box sx={styles.container}>
      <Box sx={styles.tour}>
        <TourVirtual src="https://repositoriodocumentace.online/escola-de-artes-e-oficios/index.htm" />
        <Box sx={styles.miniMenu}>
          <MiniMenu
            title= "Escola de Artes e Ofícios Thomaz Pompeu Sobrinho"
            history={<History />}
            gallery={<Gallery images={homeGallery} />}
            info={
              <CardHowToFind
                address={{
                  name: "Av. Francisco Sá, 1801, Jacarecanga, Fortaleza, CE, 60010-450",
                  link: "https://goo.gl/maps/PiDrRPk4FgJrDnjaA",
                }}
                openingHours="Segunda à sexta: 08h - 17h | Sábado e domingo: (fechado)"
                contacts={{
                  phoneNumber: "(85) 3238-1244",
                  email:"contato@eao.org.br"
                }}
                socialMedia={{
                  Instagram: "https://www.instagram.com/escolaarteseoficios.tps/",
                  Facebook:"https://www.facebook.com/earteseoficios/",
                  YouTube:"https://www.youtube.com/channel/UCu_kveaXtXEw2LqfJxlMvOg/videos",

                }}
              />
            }
          />
        </Box>
      </Box>
    </Box>
  );
};
