import img01 from "./img01.jpg";
import img11 from "./img11.jpg";
import img02 from "./img02.jpg";
import img03 from "./img03.jpg";
import img04 from "./img04.jpg";
import img05 from "./img05.jpg";


export const images = {
    img01,
    img11,
    img02,
    img03,
    img04,
    img05,
}