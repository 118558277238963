import { Box } from "@mui/material";
import { MiniMenu } from "shared/components/menu-monument/MiniMenu";
import { TourVirtual } from "shared/components/tour-virtual";
import { History } from "./components";
import { useStyles } from "./BECE.style";
import { CardHowToFind } from "shared/components/card-how-to-find";
import homeGallery from "./BECEgallery";
import { Gallery } from "shared/components/gallery/Gallery";

export const BECE: React.FC = () => {
    const styles = useStyles();
    return(
    <Box sx={styles.container}>
      <Box sx={styles.tour}>
        <TourVirtual src="https://repositoriodocumentace.online/biblioteca-publica-estadual-do-ceara/index.htm" />        
        <Box sx={styles.miniMenu}>
          <MiniMenu
            title="Biblioteca Pública Estadual do Ceará"
            history={<History />}
            gallery={<Gallery images={homeGallery} />}
            info={
              <CardHowToFind 
              address={{
                name:"Avenida Presidente Castelo Branco, 255, Fortaleza-CE, 60010-000",
                link:"https://goo.gl/maps/e2V4AJpiH7ZTPnQq8" 
              }}
              guidedTour="Email para agendamento: visitas.bece@idm.org.br"
              accessibility="Possui alternativas de acessibilidade."
              openingHours="Terça à sexta: 9h às 20h |
              Sábado e domingo: 9h às 17h" 
              contacts={{
                phoneNumber:"(85) 3101-2545",
                email:"bece@secult.ce.gov.br"
              }}
              socialMedia={{
                Instagram:"https://www.instagram.com/bece_bibliotecaestadualdoceara/",
                Facebook:"https://www.facebook.com/bibliotecaestadualdoceara",
                YouTube:"https://www.youtube.com/channel/UC3N5bsVFMfbshyVBWK-UNMw",
                Twitter:"https://twitter.com/bece_biblioteca"
              }}
              />
          }
          />
        </Box>
      </Box>
    </Box>
    );
};