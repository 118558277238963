import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import { CardNews } from "shared/components/card-news";
import { useStyles } from "./News.style";

const newsData = [
  {
    date: "20 de junho de 2023",
    title:
      "Pesquisa da UFC sobre documentação histórica do Theatro José de Alencar é premiada em congresso internacional",
    abstract:
      "Pesquisa da Universidade Federal do Ceará sobre a documentação histórica do Theatro José de Alencar, conduzida pela equipe do Laboratório de Reabilitação e Durabilidade das Construções (LAREB), do Campus da Universidade Federal do Ceará em Russas, recebeu a premiação Vitervo O’Reilly, de melhor artigo no XIX Congresso Internacional sobre Patologia e Reabilitação das Construções (CINPAR 2023).",
    link: "https://www.ufc.br/noticias/17905-pesquisa-da-ufc-sobre-documentacao-historica-do-theatro-jose-de-alencar-e-premiada-em-congresso-internacional",
  },
  {
    date: "30 de maio de 2023",
    title:
      "Theatro José de Alencar é o primeiro do Brasil a ganhar modelo em 3D de alta precisão; projeto auxilia na manutenção e nos restauros do patrimônio",
    abstract:
      "Projeto desenvolvido por alunos e professores da UFC é a representação mais precisa já elaborada para equipamentos históricos do Ceará. Digitalização já permite visitas virtuais ao teatro",
    link: "https://agencia.ufc.br/theatro-jose-de-alencar-e-o-primeiro-do-brasil-a-ganhar-modelo-em-3d-de-alta-precisao-projeto-auxilia-na-manutencao-e-nos-restauros-do-patrimonio/",
  },
  {
    date: "19 de Abril de 2023",
    title:
      "Primeiro equipamento cultural digitalizado no estado do Ceará é tema de publicação em revista internacional. O artigo faz parte do projeto Cientista Chefe da Cultura.",
    abstract:
      "A casa José de Alencar, localizada no bairro Messejana, na cidade de Fortaleza-CE, que pertenceu ao próprio escritor, um dos grandes nomes da literatura brasileira, foi a primeira edificação histórica do estado do Ceará a ser digitalizada com tecnologia de alta resolução. O artigo aborda desde a técnica de aquisição de dados em campo através de um laser scaner até o processo de modelagem em BIM, utilizando o software Revit da Autodesk. Além disso, traz um enfoque na avaliação de danos causados na edificação ao longo do tempo e em como as ferramentas HBIM podem ser utilizadas na avaliação de anomalias em construções patrimoniais.",
    link: "http://www.campusrussas.ufc.br/noticia.php?v=1459&t=Primeiro-equipamento-cultural-digitalizado-no-estado-do-Cear%C3%A1-%C3%A9-tema-de-publica%C3%A7%C3%A3o-em-revista-internacional.-O-artigo-faz-parte-do-projeto-Cientista-Chefe-da-Cultura",
  },
  {
    date: "03 de janeiro de 2023",
    title:
      "Plataforma Documenta CE disponibiliza primeira visita virtual a equipamentos culturais do Ceará; UFC é responsável pelo projeto",
    abstract:
      "O Programa Cientista-Chefe Cultura disponibilizou a plataforma digital Documenta CE, que possibilita a visita virtual a equipamentos culturais do Ceará. O primeiro tour virtual é no Theatro José de Alencar. A UFC integra o Programa Cientista-Chefe Cultura e é a instituição responsável por realizar a digitalização desses equipamentos.",
    link: "https://www.ufc.br/noticias/17492-plataforma-documenta-ce-disponibiliza-primeira-visita-virtual-a-equipamentos-culturais-do-ceara-ufc-e-responsavel-pelo-projeto",
  },
  {
    date: "29 de outubro de 2022",
    title:
      "EQUIPAMENTOS PÚBLICOS | Pesquisadores do programa “Cientista Chefe da Cultura” atuam em parceria com a Secult-CE para promover avanços e inclusão social nas políticas culturais do Ceará.",
    abstract:
      "Inovar, redesenhar, aprimorar. Verbos que se tornam palavras de ordem para empresas se destacarem em mercados cada vez mais competitivos. Quando se trata de gestão pública, a inovação tem sido estimulada como forma de sofisticar os processos e, por conseguinte, as entregas à população. Com várias frentes de atuação, o programa 'Cientista Chefe da Cultura' tem se debruçado em fortalecer as políticas culturais no âmbito da Secretaria Estadual de Cultura (Secult-CE).",
    link: "https://mais.opovo.com.br/jornal/vidaearte/2022/10/29/cientista-chefe-da-cultura-investe-em-tecnologia-3d-em-equipamentos-cearenses.html",
  },
  {
    date: "25 de Abril de 2022",
    title:
      "Cientista Chefe Cultura promove o “Seminário Inovação para o Patrimônio Cultural” com participação da Profa.Grazia Tucci da Universidade de Florença",
    abstract:
      "O seminário “Inovação para o patrimônio Cultural” ocorre no âmbito do projeto Inovação para o Patrimônio Cultural do Ceará, coordenado pelo Prof. Dr. Esequiel Mesquita, do curso de Engenharia Civil do Campus Russas da UFC, que é um subprojeto do Programa Cientista Chefe Cultura, que tem como coordenador o Prof. Dr. Custódio Almeida. O programa tem apoio da FUNCAP e da Secretaria da Cultura do Governo do Estado do Ceará.",
    link: "http://www.campusrussas.ufc.br/noticia.php?v=1116",
  },
  {
    date: "12 de abril de 2022",
    title:
      "Campus de Russas coordena projeto de virtualização de equipamentos culturais e do patrimônio histórico do Ceará",
    abstract:
      "Imagine visitar equipamentos culturais do estado do Ceará, como a Biblioteca Pública, o Museu da Imagem e do Som ou o Theatro José de Alencar, sem sair de casa! Até junho de 2023, isso vai ser possível graças ao projeto Inovação e Tecnologia para o Patrimônio Cultural do Ceará.",
    link: "https://www.ufc.br/noticias/noticias-de-2022/16818-campus-de-russas-coordena-projeto-de-virtualizacao-de-equipamentos-culturais-e-do-patrimonio-historico-do-ceara",
  },
  
];

export const News: React.FC = () => {
  const style = useStyles();
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <Box sx={{ ...style.texts }}>
          <Box sx={style.title}>
            <Typography variant="h2">NOTÍCIAS</Typography>
          </Box>
          <Box sx={style.subtitle}>
            <Typography>Veja as últimas notícias sobre o projeto.</Typography>
          </Box>
        </Box>
        <Box
          sx={{
            ...style.container,
          }}
        >
          {newsData.map((item) => (
            <CardNews
              date={item.date}
              title={item.title}
              abstract={item.abstract}
              link={item.link}
            />
          ))}
        </Box>
      </Box>
    </>
  );
};
