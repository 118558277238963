import image1 from "./img16.jpg";
import image2 from "./img01.jpg";
import image3 from "./img07.jpg";
import image5 from "./img10.jpg";

export const images = {
    image1,
    image2,
    image3,
    image5,
}