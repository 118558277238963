import image1 from "./img01.jpg";
import image2 from "./img02.jpg";
import image3 from "./img03.jpg";
import image4 from "./img04.jpg";
import image6 from "./img06.jpg";

export const images = {
  image1,
  image2,
  image3,
  image4,
  image6,
};
