// import { makeStyles } from "@mui/styles";

export const useStyles = () => ({
  container: {
    width: "100%",
    minHeight: "90vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  background: {
    position: "absolute",
    width: "100%",
    height: "100vh",
  },
  text: {
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    alignItems: "center",
    color: "#FFFFFF",
    padding: "25px",
    backdropFilter: "blur(3px) brightness(0.4)",
    justifyContent: "space-evenly",
    margin: "0px 10%",
    "& > p": {
      fontFamily: '"Julius Sans One" !important',
      fontSize: "22px",
      fontWeight: "bold",
      maxWidth: "400px",
    },
  },
  button: {
    display: "flex",
    width: "200px",
    height: "80px",
    borderRadius: "50px",
    color: "#FFFFFF",
    border: "1px solid #FFFFFF",
    cursor: "pointer",
    background: "linear-gradient(to bottom, transparent 80px, #2A9D8F 80px)",
    backgroundSize: "200px 160px",
    justifyContent: "center",
    alignItems: "center",
    margin: "10px 0px",
  },
  tour: {
    position: "absolute",
    top: "10%",
    left: "10%",
    width: "80%",
    height: "80%",
  },
});
