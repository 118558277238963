import image3 from "./img03.jpg";
import image4 from "./img04.jpg";
import image5 from "./img05.jpg";
import image6 from "./img06.jpg";
import image7 from "./img07.jpg";
import image11 from "./img11.jpg";

export const images = {
  image3,
  image4,
  image5,
  image6,
  image7,
  image11,
};
