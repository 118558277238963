import React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useStyles } from "./MiniMenu.style";
import { useTheme, useMediaQuery } from "@mui/material";

interface TabPanelProps {
  children?: React.ReactNode;
  index?: number;
  value?: number;
}

interface MiniMenuProps {
  title?: string;
  history?: React.ReactNode;
  gallery?: React.ReactNode;
  info?: React.ReactNode;
}
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
export const MiniMenu: React.FC<MiniMenuProps> = (props: MiniMenuProps) => {
  const [value, setValue] = React.useState(0);
  const styles = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  return (
    <Box sx={{ display: "flex", bgcolor: "white", flexDirection: "column" }}>
      <Box
        sx={{
          display: "flex",
          marginLeft: "5%",
          marginTop: "5%",
          fontFamily: "Roboto Serif",
          fontSize: "3rem",
        }}
      >
        {props.title}
      </Box>
      <Box
        sx={{
          ...styles.container,
          display: "flex",
          padding: "5%",
          borderColor: "divider",
          [theme.breakpoints.down("md")]: {
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
          },
        }}
      >
        <Tabs
          orientation={isMobile ? "horizontal" : "vertical"}
          variant="scrollable"
          value={value}
          onChange={handleChange}
          sx={{ ...styles.background, minWidth: "300px" }}
          centered
          // textColor="primary"
          indicatorColor="secondary"
        >
          <Tab sx={styles.link} label="História" {...a11yProps(0)} />
          <Tab sx={styles.link} label="Galeria" {...a11yProps(1)} />
          <Tab sx={styles.link} label="Como Chegar" {...a11yProps(2)} />
        </Tabs>
        <TabPanel value={value} index={0}>
          {props.history}
        </TabPanel>
        <TabPanel value={value} index={1}>
          {props.gallery}
        </TabPanel>
        <TabPanel data-testid="Aquiiiiiiii" value={value} index={2}>
          {props.info}
        </TabPanel>
      </Box>
    </Box>
  );
};
