import { Box, Modal, Typography } from "@mui/material";
import { motion } from "framer-motion";
import { useState } from "react";
import { useStyles } from "./TourVirtual.style";

interface TourVirtualProps {
  src: string;
}

export const TourVirtual: React.FC<TourVirtualProps> = ({
  src,
}: TourVirtualProps) => {
  const styles = useStyles();
  const [visible, setVisible] = useState(false);

  const handleClick = () => {
    setVisible(!visible);
  };

  return (
    <>
      <Box sx={styles.container}>
        <Box sx={styles.text}>
          <Typography>
            Explore nossa história com uma visita virtual.
          </Typography>
          <motion.div
            style={styles.button}
            whileHover={{
              backgroundPositionY: "-80px",
              border: "1px solid #2A9D8F",
            }}
            transition={{ duration: 0.3 }}
            onClick={handleClick}
          >
            Visita Virtual
          </motion.div>
        </Box>
      </Box>
      <Modal open={visible} onClose={handleClick}>
        <Box sx={styles.tour}>
          <iframe
            width="100%"
            height="100%"
            src={src}
            allowFullScreen
            allow="xr-spatial-tracking"
          ></iframe>
        </Box>
      </Modal>
    </>
  );
};
