// import { makeStyles } from "@mui/styles";

export const useStyles = () => ({
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignContent: "center",
    background: "#2a9d8f",
    padding: "2%",
    ["@media (max-width:900px)"]: {
      flexDirection: "column",
      justifyContent: "space-evenly",
      alignItems: "center",
    },
  },
  button: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    backgroundColor: "transparent",
    "& a": {
      color: "#FFFFFF",
    },
  },
  text: {
    fontWeight: "bold",
    fontSize: "1.5rem",
    color: "white",
  },
  support: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-evenly",
    flexDirection: "row",
    ["@media (max-width:900px)"]: {
      flexDirection: "column",
      justifyContent: "space-evenly",
      marginTop: "2rem",
      alignItems: "center",
    },
    "& > div": {
      height: "200px",
      width: "200px",
      display: "flex",
      alignItems: "center",
      "& img": {
        width: "100%",
      },
      ["@media (max-width:900px)"]: {
        alignItems: "center",
      },
    },
  },
});
