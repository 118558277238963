// import { makeStyles } from "@mui/styles";
// import { createTheme } from "@mui/material";

export const useStyles = () => ({
  logo: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    padding : "1rem",    
    // marginBottom : "0.8rem",
    marginLeft: "5rem",
    "& img" : {
      transform : "scale(1.3)",
      width : "10rem",
    },
  },
  navLink: {
    display: "flex",
    "& > div": {
      display: "flex",
      cursor: "pointer",    
      alignItems: "center",
      textDecoration: "none",  
      "&:hover" :{
        filter: "brightness(0.5)",        
      },
    },  
  },
});
