import { Box, Typography } from "@mui/material";
import { SlideIn } from "shared/components/slideIn";
import { useStyles } from "./history.style";
import { images } from "assets/img/history/escola-gastronomia";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";

export const History: React.FC = () => {
    const styles = useStyles();

    return (
        <Box sx={styles.container}>
                    <Box sx={styles.text1}>
                        <Box>
                            <Typography>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;O ato de se alimentar é uma condição humana, faz parte do que 
                                necessitamos de forma básica para garantirmos a nossa própria sobrevivência enquanto seres vivos. 
                                Buscar alimentos e saciar a fome é algo que presenciamos desde o surgimento do ser humano no planeta 
                                terra. Entretanto, com as transformações que ocorreram ao longo da nossa trajetória, o hábito de comer 
                                foi se modificando e sendo modificado.

                            </Typography>
                            <Typography>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Por exemplo, nem em todas as culturas o hábito de se alimentar 
                                usando as mãos é visto como um sinal de falta de educação, o uso de talhares para comidas específicas 
                                não é algo presente em todas as sociedades, alguns alimentos são comuns para alguns povos, enquanto 
                                outros nem se quer possuem o que comer...
                            </Typography>
                            <Typography>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;O que queremos trazer aqui como reflexão é pensar que o ato de se 
                                alimentar, uma condição básica de sobrevivência para qualquer espécie ao longo da sua jornada pelo 
                                planeta, foi dotado de significado por povos ao longo da história, comer é mais do que a busca de 
                                nutrientes, é um símbolo cultural, é força, é poder e desigualdade.
                            </Typography>
                        </Box>
                        <img src={images.img01} />
                    </Box>
                <Typography>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Com a descoberta do fogo tivemos uma primeira grande revolução no hábito de alimentar, a possibilidade do cozimento, 
                algo que facilitou a ingestão de alimentos, matava hospedeiros indesejados que poderiam trazer doenças para o seres 
                humanos, principalmente em carnes, facilitou a ingestão e garantiu o melhor aporte nutritivo. Essas mudanças afetaram 
                inclusive características físicas humanas, como os maxilares e tamanho do intestino delgado.
                </Typography>
                <Typography>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Com o avanço histórico, passamos a descobrir novas formas de produção de 
                    alimentos, principalmente após a revolução agrícola. No Egito Antigo e Mesopotâmia possuíamos um grande consumo 
                    de cereais, como a cevada, por exemplo. O avanço da agricultura também proporcionou a produção de excedentes. 
                    Ao longo dos séculos, o comércio de alimentos tornou-se uma força motriz importante na história da alimentação. 
                    As rotas comerciais antigas, como a Rota da Seda e as rotas marítimas, conectaram diferentes regiões do mundo, 
                    permitindo a troca de produtos alimentícios e especiarias. Isso influenciou profundamente as dietas e os hábitos 
                    alimentares das pessoas, com a introdução de novos alimentos e temperos em diferentes culturas.
                </Typography>
                <Box sx={styles.text2}>
                    <Box>
                <Typography>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;No período das grandes navegações, novos continentes foram invadidos, 
                    resultando no chamado "intercâmbio colombiano". Essa troca de alimentos entre o Velho Mundo e o Novo Mundo 
                    transformou a culinária global de maneira significativa. Alimentos como batatas, tomates, milho, pimentas e 
                    chocolate foram levados da América para a Europa, enquanto trigo, arroz, café e gado foram introduzidos nas 
                    Américas e em outras partes do mundo. Processo que custou muito aos povos nativos da América, tendo a sua cultura, 
                    vida e recursos colocados em risco.
                </Typography>
                </Box>
                <img src={images.img11} />
                </Box>
                <Typography>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A Revolução Industrial no século XVIII trouxe mudanças radicais na forma como 
                    os alimentos eram produzidos, processados e distribuídos. A mecanização da agricultura, o desenvolvimento de 
                    técnicas de conservação de alimentos e o surgimento de fábricas de processamento alimentar alteraram a relação 
                    entre as pessoas e sua alimentação. A produção em massa de alimentos e o surgimento de redes de supermercados 
                    transformaram os hábitos alimentares da população, criando uma maior dependência de alimentos processados e 
                    industrializados.
                </Typography>
                
                    <Box sx={styles.text2}>
                        <Box>
                            <Typography>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A religião também possui a sua interferência e simbolismo alimentar,
                                 igrejas monastérios, terreiros e as práticas plurais religiosas também carregam a alimentação com 
                                 símbolos de medo, castigo, proibição, metáforas e tentativas de controlar a população, basta que 
                                 lembremos, por exemplo, da simbologia dada a maçã por alguns cristãos como a “fruta do pecado”. 
                                 Além da gula como um dos pecados capitais e a simbologia do corpo e sangue de Jesus no pão e no vinho.
                            </Typography>
                            <Typography>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Os primeiros livros de receitas começam a aparecer ao longo do 
                                século XV, sendo populares nas cozinhas de elite, presentes na França, alguns outros países da Europa 
                                incluindo a família real portuguesa. Além do cozinhar e do comer, o como comer também foi transformado 
                                e cercado de significados. Nobert Elias, em sua obra O Processo Civilizador, nos apresenta a construção 
                                de hábitos em torno da mesa de jantar, como a predisposição das cadeiras ao redor da mesa e quem 
                                ocuparia cada espaço, além do uso de talheres específicos para cada refeição. Um tentativa da burguesia 
                                de criar uma identidade de diferenciação em relação aos trabalhadores, mas ainda com traços da nobreza.
                            </Typography>
                        </Box>
                        <img src={images.img02} />
                    </Box>
                    <Typography>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Em outros momentos, como na Revolução Francesa, podemos observar 
                                a falta de alimentos para todos somada a outras desigualdades sendo motivo de início para uma 
                                revolução. Na Rússia revolucionária, em 1917, a fome também um dos motivos do levante populacional. 
                                A história nos mostra a alimentação e suas transformações, significados e valores ao longo do tempo, 
                                indo além da nutrição, comer é direito, é cultura, sabor e sobrevivência.
                            </Typography>
                {/* </SlideIn> */}
                {/* <SlideIn movement={200}> */}
                    <Box sx={styles.text1}>
                        <Box>
                            
                            <Typography>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A cultura local também marca os seus traços culinários, no Ceará, 
                                por exemplo, possuímos pratos como o sarrabulho, buchada e panelada, alimentos que carregam marcas da 
                                história cearense. Nesse cenário foi inaugurada no dia 29 de junho de 2018, tendo como alguns dos seus 
                                objetivos a difusão, reconhecimento e valorização da cultura alimentar do Ceará, a Escola de 
                                Gastronomia Social Ivens Dias Branco. A escola é uma parceria entre os setores público e privado, 
                                sendo o Governo do Estado do Ceará o responsável pela manutenção e custeio das atividades oferecidas. 
                                O local atual da escola foi cedido pela Prefeitura de Fortaleza e a construção do prédio foi feita pelo
                                grupo M. Dias Branco.
                            </Typography>
                            <Typography>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A Escola de Gastronomia é gerida pelo Instituto Dragão do Mar, a 
                                iniciativa é inspirada na experiência da Porto Iracema das Artes, Escola de criação e formação do Ceará.
                                 A Escola também integra o projeto de requalificação do Morro Santa Terezinha, localizado no bairro do 
                                 Mucuripe. A Escola oferece curso de formação em gastronomia com certificação em nível básico e técnico 
                                 com certificação e garantia do Governo do Estado através da Secretaria de Cultura.
                            </Typography>
                        </Box>
                        <img src={images.img03} />
                    </Box>
                {/* </SlideIn> */}
                <Typography>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A Escola de Gastronomia Ivens Dias Branco tem suas raízes profundamente 
                    ligadas à história do empresário Ivens Dias Branco, um investidor do setor alimentício. Sua trajetória 
                    empreendedora na gastronomia inspiraram a criação dessa instituição de ensino. Ivens Dias Branco nasceu em 1932, 
                    no Ceará. Ele foi o fundador e presidente do Grupo M. Dias Branco, uma das maiores empresas de alimentos do país, 
                    especializada na produção de massas e biscoitos.
                </Typography>
                {/* <SlideIn movement={200}> */}
                {/* </SlideIn> */}
                <Typography>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Com base nessa visão, a Escola de Gastronomia Ivens Dias Branco foi fundada com a intenção de oferecer uma educação 
                culinária de alta qualidade e fomentar o crescimento da gastronomia local e nacional. A escola se tornou uma extensão 
                do legado de Ivens Dias Branco, uma maneira de perpetuar sua proximidade com a culinária. Seus programas educacionais 
                abrangem uma ampla gama de tópicos, desde as técnicas culinárias mais básicas até as mais avançadas, além de explorar 
                temas como sustentabilidade, gestão de negócios na indústria alimentícia e tendências gastronômicas contemporâneas.
                </Typography>
                <Typography>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Além disso, a escola promove uma abordagem holística para a gastronomia, 
                    incorporando a compreensão da história da comida, das culturas alimentares e das tendências contemporâneas. 
                    Workshops, palestras e eventos especiais frequentemente apresentam chefs convidados e especialistas da área, 
                    enriquecendo a experiência educacional dos estudantes.
                </Typography>
                {/* <SlideIn movement={200}> */}
                {/* </SlideIn> */}
                <Typography>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Parcerias estratégicas com restaurantes, hotéis e empresas de alimentos 
                    permitem que os alunos experimentem o ambiente real de trabalho, colocando suas habilidades à prova e construindo
                     redes profissionais desde cedo. No final de sua jornada na escola, os estudantes emergem não apenas com um diploma,
                      mas com uma paixão renovada pela culinária, uma compreensão profunda da arte de cozinhar e as ferramentas para
                       explorar novos horizontes na gastronomia.
                </Typography>
                <Typography>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Localizada na Rua Manuel Dias Branco, número 80, no Cais do Porto, a Escola 
                    possui forte impacto social, buscando levar a gastronomia para o seu mais amplo significado, sem deixar de pensar 
                    nas questões do entorno da própria escola, cultura local, preservação e difusão de conhecimentos. A culinária e a 
                    gastronomia vivem na Escola Social Ivens Dias Branco.
                </Typography>
        </Box>
    );
};
