import React, {useRef, useEffect, useContext, useMemo} from "react";
import {
  AppBar,
  Toolbar,
  CssBaseline,
  Box,
  Grid,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { useStyles } from "./MainMenu.style";
import { Link } from '@mui/material';
import { MenuVertical } from "./subcomponents/menu-main-vertical";
import logoMenu from "assets/img/logoMenu_fundo_branco.png";
import { MenuHorizontal } from "./subcomponents/menu-main-horizontal";
import {MenuVisibilityContext} from "shared/context";

interface MainMenuPros {
  shadow: boolean;
}

export const MainMenu: React.FC<MainMenuPros> = ({ shadow }: MainMenuPros) => {
  const styles = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const menuIsVisible = useContext(MenuVisibilityContext);

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current) {
      if (menuIsVisible.isMenuVisible)
        ref.current.style.background = theme.palette.primary.main;
      else
        ref.current.style.background = "transparent";
    }
  }, [menuIsVisible.isMenuVisible]);

  return (
    <AppBar ref={ref} sx={{background: "transparent", transition: "2s"}} elevation={shadow ? 4 : 0}>
      <Box>        
        <CssBaseline /> 
        <Toolbar sx={{alignItems: "stretch", justifyContent: "space-between", display: "flex", [theme.breakpoints.down("md")]: { flexDirection: "row-reverse", justifyContent: "flex-end", gap: "1rem"}}}>
          <Box sx={{ ...styles.logo, transition: "2s", opacity: menuIsVisible.isMenuVisible ? "1" : "0", [theme.breakpoints.down("md")]: { marginLeft: "0rem"}}}>
            <Link href="/">
              <img src={logoMenu} alt="Logo Patrimônio Cultural" />
            </Link>
          </Box>
          {isMobile ? (
            <MenuVertical/>
          ) : (
            <MenuHorizontal/>
          )}
        </Toolbar>
      </Box>
    </AppBar>
  );
};
