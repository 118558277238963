import { Route, Routes } from "react-router-dom";
import { links } from "content/routes";

export const AppRouter: React.FC = () => {
  return (
      <Routes>
        {
          links.map( (link) => <Route path={link.path} element={link.page}/>)
        }                
      </Routes>
  );
};

export default AppRouter;