export const useStyles = () => ({
  root: {
    color: "red",
    width: "250px",
    img: {
      objectFit: "cover",
      width: "250px",
      height: "250px",
    },
  },
});
