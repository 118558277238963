import { Box, Typography } from "@mui/material";
import { useStyles } from "./History.style";
import { images } from "assets/img/history/MIS";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";

export const History: React.FC = () => {
  const styles = useStyles();

  return (
    <Box sx={styles.container}>
      <Box>
          <Box sx={styles.text1}>
            <Box>
              <Typography>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Por muito tempo a história,
                enquanto ciência, através daqueles que a escreveram, foi
                utilizada enquanto mecanismo de repressão e generalização da
                própria humanidade. Ao observarmos o uso da preposição, seguida
                de um pronome demonstrativo, “daqueles”, de alguma forma já
                podemos analisar uma, dentre várias exclusões, que foram
                forjadas no fazer histórico: Ela (História) foi por muito
                produzida por homens, excluindo das suas linhas mulheres e
                outros grupos que não faziam parte dos seus pares.
              </Typography>
              <Typography>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Essa história excluiu, além
                das mulheres, os prisioneiros de guerras, escravos,
                trabalhadores, grupos marginalizados, todos aqueles que não
                representavam figuras consideradas heroicas, nomes atrelados ao
                Estado ou a Igreja, uma história que olhava apenas para o
                passado, anulando qualquer criticidade e propondo a
                possibilidade de um historiador neutro para a busca de uma
                verdade absoluta e inquestionável. O caminho para um passado
                integral e puro, que pertencia apenas a alguns poucos.
              </Typography>
            </Box>
            <img src={images.image6} />
          </Box>
        <Typography>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Esse modelo histórico, além de
          excluir histórias e pessoas, também acabou por não aceitar muitas
          produções humanas. A pesquisa foi limitada as fontes consideradas
          oficiais, aquelas produzidas pelo próprio Estado, o que afastava as
          outras representações de um determinado momento através, por exemplo,
          de jornais, folhetins, poemas, poesias, fotografias, as fontes
          históricas eram ignoradas na sua diversidade, dificultando ainda mais
          a percepção dos eventos pelo olhar daquelas que não andavam pisando em
          grandes tapetes vermelhos, que não tinham coroas de ouro sob suas
          cabeças, que não sentavam em tronos reluzentes, mas construíram com o
          seus corpos, com sangue e suor, essa mesma história.
        </Typography>
        <Typography>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A escrita foi utilizada como fator
          determinante para definir a história de um povo, já que imagens, sons
          e outras produções humanas não eram percebidas como forma de também se
          perceber a vida de uma sociedade. Aprendemos durante muito tempo nas
          escolas que existiu uma “pré-história”, aqueles que não possuíam
          história por não terem contato com a escrita. Mas façamos a seguinte
          reflexão: seria a escrita a única forma de se construir/conhecer uma
          história?
        </Typography>
        <Typography>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Quando entendemos que as fontes
          (material utilizado para a compreensão de determinado momento da
          história) podem ser compreendidas como qualquer produção humana no
          tempo e espaço, novos objetos passam a surgir em nosso imaginário na
          perspectiva histórica, nos levando além da escrita e abrindo portas
          para que a pré-história seja vista como, de fato, história. Afinal de
          contas, a cerâmica e as pinturas rupestres também nos contam muito
          sobre os seus produtores.
        </Typography>

          <Box sx={styles.text2}>
            <Box>
              <Typography>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Partindo dessa compreensão,
                um novo mundo fica à disposição do conhecimento histórico, que
                passa a dialogar como novas formas de percepção e representação
                da história. Ainda na primeira metade do século XIX, tivemos o
                advento da fotografia, com o seu desenvolvimento a partir dos
                avanços tecnológicos, vários festivais surgiram, com diferentes
                categorias, retratando a natureza, pessoas e olhares sobre povos
                e questões sociais.
              </Typography>
              <Typography>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Já na segunda metade do
                século XIX, foi criado o cinematógrafo, sendo assim possível o
                desenvolvimento do cinema. Uma outra forma de representar e
                observar leituras sobre as sociedades humanas, sejam elas
                recentes ou passadas. Momentos antes descritos em textos passam
                a ser interpretados a partir da perspectiva de diretores,
                artistas e indústrias cinematográficas, além das trilhas sonoras
                que nos levam até outro ponto significativo da produção humana:
                o som.
              </Typography>
              <Typography>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A música e outras expressões
                sonoras também compõem importantes instrumentos para
                significação humana. Rituais sagrados, formas de comunicação,
                envio e recebimento de mensagens, independente da forma, a
                oralidade é um importante instrumento histórico da humanidade.
              </Typography>
            </Box>
            <img src={images.image7} />
          </Box>
        <Typography>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Algumas das fontes citadas passam
          a ganhar espaço principalmente através da chamada Escola dos Annales,
          movimento que revolucionou a percepção sobre o fazer histórico,
          levando em consideração o papel do historiador, criticidade, tipos e
          tratamentos dados as fontes. Em meio a tantas mudanças, os museus
          passam também a dar maior destaque a formas mais variadas de memórias,
          de documentos, monumentos, de fontes. Surgem espaços voltados mais
          especificamente para a imagem e o som, conhecidos em parte do Brasil
          como MIS: Museu da Imagem e do Som. Existem em localidades como São
          Paulo, construído já na segunda metade do século XX; Em Alagoas, no
          Amazonas, no Rio de Janeiro e aqui próximo de nós, o Museu da Imagem e
          do Som do Ceará, localizado na capital, Fortaleza.
        </Typography>
          <Box sx={styles.text1}>
            <Box>
              <Typography>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;O museu é visto como um
                equipamento cultural, de caráter virtual e físico, sendo
                responsável pela preservação, pesquisa e difusão da memória
                audiovisual de todo o Estado do Ceará, tendo foco na cultura, na
                política, na antropologia, na história e nas tradições
                populares, produzidas de forma variada, seja dentro do próprio
                Estado ou fora dele. O espaço faz parte da Rede Pública de
                Equipamentos Culturais da Secretaria da Cultura do Estado do
                Ceará (Secult Ceará).
              </Typography>
              <Typography>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;O Museu da Imagem e do Som
                do Ceará foi inaugurado no ano de 1980, funcionando no subsolo
                do prédio da Biblioteca Pública Governador Menezes Pimentel,
                atual BECE, fazendo parte do antigo Departamento de Patrimônio
                de Bibliografia. Depois de alguns anos, o Museu da Imagem e do
                Som passou a integrar o Departamento de Audiovisual, tendo o seu
                acervo totalmente ampliado. O espaço recebeu novos equipamentos,
                documentos variados, material audiovisual, incluindo: fitas de
                áudio, fotos e cromos, o cordel do antigo CERES – Centro de
                Referência Cultural e uma coleção de filmes da TV Educativa.
              </Typography>
            </Box>
            <img src={images.image3} />
          </Box>
        <Typography>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;No processo de reestruturação, O
          MIS foi levado para a sua atual sede, na Avenida Barão de Studart,
          410; no dia 7 de agosto de 1996. A atual casa foi projetada pelo
          arquiteto José Barros Maia, o Mainha, para ser residência do então
          senador Fausto Augusto Borges Cabral, quando foi inaugurada em
          novembro de 1951. Em 1963, por determinação do então Governador,
          Virgílio Távora, foi a residência oficial e sede do Governo do Estado,
          até o ano de 1971. Depois de desapropriada pelo Governo Estadual, a
          casa funcionou como sede do Museu Antropológico do Ceará, até o ano de
          1990. A casa possui dois leões em sua entrada, vindos da cidade de
          Porto, em Portugal, datados do início do século XX. Também é possível
          encontrar flores e jardins com uma perspectiva arquitetônica
          harmonioso.
        </Typography>
          <Box sx={styles.text2}>
            <Box>
              <Typography>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Em 2018, o Museu da Imagem e
                do Som do Ceará passa por uma grande reestruturação e reparo,
                recebendo um prédio anexo de 5 andares, sendo dois deles
                subterrâneos. Unindo as duas instalações, temos uma ampla praça
                funcionando como espaço de convivência para atividades externas.
                Os espaços recebem novos equipamentos, com tecnologia atual,
                expandindo assim os serviços ofertados pelo museu. Nas
                proximidades do MIS, temos ainda o Conjunto Arquitetônico do
                Palácio da Abolição, atual sede da administração estadual, com
                assinatura do arquiteto Sérgio Bernardes.
              </Typography>
              <Typography>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A reinauguração do MIS
                ocorre no dia 31 de março de 2022, batizando o espaço de
                “Complexo Museu da Imagem e do Som Chico Albuquerque”, reunindo
                o casarão da década de 50 e o novo prédio. O novo espaço conta
                com: laboratórios de conservação e higienização, laboratório de
                digitalização e restauro digital, laboratório fotográfico,
                reserva técnica ampla climatizada, ambiente para pesquisa e
                catalogação, estúdio de áudio e de mixagem e restauro de áudio,
                estúdio de vídeo, ilhas de edição, além de diversos espaços
                expositivos e pedagógicos com equipamentos digitais interativos.
              </Typography>
            </Box>
            <img src={images.image4} />
          </Box>
        <Typography>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Segundo a assessoria do espaço,
          esses são os critérios para uso dele:
          <span style={styles.quotes}>
            <FormatQuoteIcon sx={styles.quoteOpenIcon} />
            <span>
              O MIS é um espaço de convivência. Um espaço de diálogo, de troca
              de saberes, aprendizado, reflexão, conhecimento, fruição artística
              e lazer. Para que todos se sintam à vontade na ida ao Museu,
              disponibilizamos as seguintes orientações: para acessar as
              exposições, é necessário apresentar documento de identificação com
              foto; menores de 12 anos devem estar acompanhados de pais ou
              responsáveis; nos espaços expositivos e auditório, o consumo de
              alimentos e bebidas não é permitido; a área externa do Museu é
              aberta a todos os animais. Já nos espaços internos do Museu, podem
              circular apenas cães-guias acompanhados de seus donos; você pode
              fotografar tudo o que quiser no MIS; o uso de telefones celulares
              e rádios de comunicação é permitido em todo o Museu. Em respeito
              aos demais visitantes, recomendamos evitar falar ao telefone nos
              espaços expositivos e manter seu dispositivo no modo silencioso;
              por determinação da Lei n° 9.294, de 15 de julho de 1996, a
              restrição ao fumo aplica-se a áreas total ou parcialmente fechadas
              e aos locais de circulação ou permanência de visitantes e
              funcionários; conforme decreto vigente, recomenda-se o uso de
              máscara de proteção para acessar os espaços internos. (SECULT,
              2022, disponível em:{" "}
              <a
                href="https://mapacultural.secult.ce.gov.br/espaco/265/"
                target="_blank"
              >
                Mapa Cultural do Ceará - MIS - Museu da Imagem e do Som do Ceará
                - Mapa Cultural do Ceará (secult.ce.gov.br)
              </a>
              ).
            </span>
            <FormatQuoteIcon sx={styles.quoteCloseIcon} />
          </span>
        </Typography>
          <Box sx={styles.text1}>
            <Box>
              <Typography>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;No novo MIS também é
                possível encontrar biblioteca em formato físico e digital, o
                Museu recebeu, no seu processo de reinauguração, a doação da
                biblioteca pessoal do fotógrafo Thomaz Farkaz (1924 – 2011). A
                coleção foi doada por José Possi Neto, herdeiro do espólio da
                viúva de Farkaz, Marly Mariano. Essa biblioteca recebeu o nome
                de “Marly Mariano & Thomaz Farkaz”, em homenagem ao casal.
              </Typography>
              <Typography>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A ideia é a de consolidar o
                espaço como importante equipamento cultural estratégico, para
                preservação, difusão e reflexão sobre a memória, principalmente
                a cearense. O espaço ganha um caráter mais democrático,
                expansível e acessível, através da Secretária de Cultura do
                Estado do Ceará – SECULT e das parcerias com outras
                instituições, dando alinhamento às políticas sobre as artes,
                formação, cidadania, economia e buscando contato com municípios
                do interior do Estado. O MIS conta com uma ampla programação,
                exposições e serviços que podem ser encontrados através das
                redes de comunicação do Museu.
              </Typography>
            </Box>
            <img src={images.image5} />
          </Box>
      </Box>
    </Box>
  );
};
