import { Box, Typography } from "@mui/material";
import { useStyles } from "./history.style";
import { images } from "assets/img/history/tja";

export const History: React.FC = () => {
  const styles = useStyles();

  return (
    <Box sx={styles.container}>
      <Box>
          <Box sx={styles.text1}>
            <Box>
              <Typography>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Theatro, com “h”. Referência
                à escrita de outro século, assim como o seu nome, José de
                Alencar. Mas, também como o escritor nascido em terras
                alencarinas, o theatro vai para além do seu tempo. Nascido na
                antiguidade, desde os tempos clássicos de Grécia e Roma, o
                teatro possui diversas funções em uma sociedade: entreter, fazer
                rir, chorar, dramas, sátiras e também educar. Moldar. Mostrar
                exemplos de comportamento.
              </Typography>
              <Typography>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Tais características foram
                mantidas por séculos. No século XIX, por exemplo, além do cunho
                de diferenciação social, o teatro ganhou toques de glamour
                através do embelezamento do espaço e o tom de modernização, algo
                muito comum na Europa e que chega ao Brasil, principalmente
                através do contexto da Belle Époque.
              </Typography>
              <Typography>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Entre as décadas de 1870 e o
                início da Grande Guerra, em 1914, a Europa vive um ar de cidades
                cosmopolitas que passa a se espalhar pelo mundo, incluindo
                Fortaleza. A ideia era uma: moderno. E a modernização seria o
                resultado de um espelho das cidades europeias, principalmente
                Paris e Viena. O enriquecimento de Fortaleza graças ao ouro
                branco (algodão), a exploração do trabalho de retirantes que
                fugiram para a capital devido à seca e um cenário intelectual e
                estrutural vindo do outro lado do oceano começam a transformar a
                cidade.
              </Typography>
            </Box>
            <img src={images.image6} />
          </Box>
        <Typography>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Iluminação, telefones, telégrafos,
          bondes, praças, parques, jardins, modos de agir, vestir, falar,
          alimentação, os cafés. São diversas mudanças, mas faltava algo: O
          teatro. Desde os tempos imperiais havia o interesse em um teatro
          oficial, devido à falta de estrutura dos teatros particulares, o que
          afastou muitas companhias de teatro da então Província. Os projetos
          criados já na segunda metade do século XIX não foram aprovados devido
          à dificuldade financeira e temor por um possível fracasso do espaço.
        </Typography>
        <Typography>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;O incomodo aumentava, pois além da
          ausência de um dispositivo comum em cidades na Europa, algumas
          capitais no Norte já possuíam o seu teatro há algum tempo. Foi no
          período republicano que os projetos voltaram a demonstrar novamente o
          interesse por um teatro oficial. José Bezerril, à frente do governo do
          Estado, foi o responsável pela autorização do fincamento da pedra
          fundamental do theatro, na então Praça do Patrocínio, local onde
          provavelmente deveria ter sido erguido um mercado. A praça mais tarde
          passou a se chamar Marquês do Herval e, atualmente, José de Alencar.
        </Typography>
        <Typography>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A obra não foi concluída. Em 1896,
          Nogueira Accioly, à frente da direção do Estado, mandou demolir o
          teatro. Os motivos foram problemas no parecer de construção,
          imperfeição e ser uma casa muito pequena. Essa foi a primeira obra no
          Ceará utilizando concreto simples. Accioly promoveu um concurso
          internacional procurando um novo projeto para o teatro. O mais próximo
          que conseguiu foi uma produção italiana que não foi concretizada
          naquele momento.
        </Typography>
          <Box sx={styles.text2}>
            <Box>
              <Typography>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Com o fim do seu mandato em
                1900, mais uma vez o espaço não foi erguido. Pedro Augusto
                Borges assume e fica até 1904 no governo, quando Accioly volta e
                resolve dar prosseguimento a casa oficial de espetáculos. Neste
                mesmo ano a Assembleia Legislativa aprovou a construção do
                espaço pelo valor de 400 contos de réis. Até aqui a cidade não
                possuía ainda um teatro oficial. O São Luís estava fechado desde
                1896, e os espetáculos ficavam concentrados no Clube Iracema e
                no simples Teatro das Variedades.
              </Typography>
              <Typography>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Apenas em 1908, em seu
                terceiro mandato, Accioly deu início de fato as obras do teatro.
                Criou uma “Directoria de construção do Teatro” para acompanhar
                de forma bem próxima as obras. Pouco se sabe sobre as ações da
                Directoria, mas os membros vinham de formação militar e eram
                próximos ao governo de Accioly, incluindo parentes e amigos. No
                projeto do teatro aparecem informações como a expressão
                teatro-jardim, mesmo não deixando claro o seu significado. O
                jardim não foi erguido como planejado, assim como o foyer, um
                espaço de convívio dentro do Teatro e com caráter mais elegante,
                porém construído com difícil acesso. A obra seria em estrutura
                metálica, tendo a sua fachada em estilos art nouveau e
                conríntio. Deveriam ser estas as seções do Teatro: 1. Recepção e
                Foyer 2. Jardim 3. Plateia e 4. Palco e Anexos.
              </Typography>
            </Box>
            <img src={images.image2} />
          </Box>
          <Box sx={styles.text1}>
            <Box>
              <Typography>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Outra característica
                importante no projeto era o caráter do teatro à italiana, o
                modelo faz menção ao teatro italiano renascentista e
                pós-renascentista, dialogando com a cultura clássica. O espaço
                passa a ser dividido em blocos com intercomunicação, cada um
                possuindo a sua função, assim seriam formadas as “4 secções” do
                Teatro José de Alencar.
              </Typography>
              <Typography>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Estruturalmente o projeto
                também traz forros de madeira, paredes de alvenaria de tijolos
                com figuras decorativas contendo figuras e padrões, cenários
                móveis, também pintados e com telões de subir e descer, a
                estrutura metálica, a coberta de zinco por ser um material leve,
                paredes envoltórias grossas para evitar o barulho externo e o
                Planfod de madeira, vindo em arco ou abaulado para atender à
                acústica e elegância.
              </Typography>
              <Typography>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;O modelo à italiana, explica
                a divisão da sala de espetáculos em 4 pavimentos: térreo com
                cadeiras, frizas, camarotes e o das torrinhas ou geraes. Isso
                também explica a chamada sala ou amphitheatro, tendo forma de
                ferradura. Palco, camarins e corredores possuem altura mais
                elevada, tudo com base no que eram considerados os melhores
                teatros da época.
              </Typography>
            </Box>
            <img src={images.image3} />
          </Box>
        <Typography>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;As construções de ferro e aço
          passaram pelas Casas Boris (Boris Frères), importante estabelecimento
          comercial do Estado e pela Walter Macfarlane &amp; C°, responsável por
          executar o teatro de ferro. A Walter Macfarlane &amp; C° possuía sede
          em Glasgow, na Escócia, já havia participado de diversas outras obras
          no Estado, como as gradis da parede do açude do Cedro, da construção
          do atual prédio da Caixa Econômica Federal e de outras obras pelo
          país, como a Estação da Luz, em São Paulo. Glasgow era a segunda maior
          cidade do império britânico, após Londres, possuindo a St. Enoch
          Station, de onde parece ter vindo à inspiração para as colunas e arcos
          do Teatro José de Alencar.
        </Typography>
          <Box sx={styles.text2}>
            <Box>
              <Typography>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Por conta da variedade de
                influências arquitetônicas, o teatro recebeu um termo
                considerado pejorativo à época, denominado ecletismo
                arquitetônico, usado durante a primeira metade do século XX e
                criado por modernistas para caracterizar obras com variedade na
                sua produção, desde as fontes greco-romanas, até aspectos mais
                formais. O Teatro José de Alencar era descrito como estylo
                Renascença, sacadas de ferro, estylo corinthio, do mesmo estylo,
                platibanda de frontão e cabeça de mulher emmoldurada numa
                concha. O estilo eclético está atrelado à crise de identidade da
                burguesia durante o século XIX, mais precisamente na França. As
                mudanças ocorrendo de forma acelerada parecem ter gerado uma
                “desorientação estética”.
              </Typography>
              <Typography>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Assim temos um teatro à
                italiana, com influência inglesa, de Glasgow, de Paris e do
                período greco-romano. Essas características também são visíveis
                nas pinturas e esculturas, trazendo figuras comuns à época. A
                pintura era feita com estresidos, placas de papelão
                envernizadas, delineadas com os modelos escolhidos, ganhando
                mais destaque na parte interna do teatro. Foram realizadas por
                José de Paula Barros e Raimundo Ramos (Cotoco), conhecidos
                boêmios da cidade. Internamente o teatro conta com várias
                referências as obras de José de Alencar, além dos nomes já
                citados, outros também participaram da construção interna, como
                Herculano Ramos, responsável pela cenografia e que contou com a
                participação de Gustavo Barroso.
              </Typography>
            </Box>
            <img src={images.image4} />
          </Box>
        <Typography>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;O teatro foi inaugurado
          oficialmente no dia 17 de junho de 1910, quando tocou a banda
          sinfônica do Batalhão de Segurança, regida por Luigi Maria Smido e
          Henrique Jorge, a inauguração se estendeu até a praça. O Teatro José
          de Alencar sofreu muitas críticas no seu processo de abertura, muito
          também pelos problemas estruturais, como o do jardim e do foyer,
          fazendo com que passasse por algumas reformas.
        </Typography>
          <Box sx={styles.text1}>
            <Box>
              <Typography>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A primeira veio logo em 1918
                quando foram introduzidas as instalações elétricas e mais duas
                escadas, fundidas aqui mesmo no Ceará. Em 1936, temos o projeto
                para mais uma reforma com o objetivo de corrigir erros na
                construção, o que não aconteceu de forma total, indo até 38 sob
                a chefia de José Barros Maia. Em 57, as cadeiras de palhinha
                (estilo austríaco), que vemos atualmente no teatro, foram
                substituídas por poltronas estofadas. Em 1973 foi demolido o
                Centro de Saúde, o projeto liderado por Burle Marx usou o espaço
                para construção do jardim lateral. Nesse mesmo ano houve a
                recomposição da parte metálica e o retorno das já citadas
                cadeiras de palhinha.
              </Typography>
              <Typography>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Mesmo projetado para ser um
                teatro-jardim, o jardim só foi concluído em 1975. No dia 10 de
                agosto de 1964 o prédio foi então tombado pelo IPHAN. Entre os
                anos de 1989 e 91 tivemos a recomposição do jardim e construção
                do espaço cênico ao ar livre para a apresentação de espetáculos,
                nesta mesma reforma foi erguido o prédio anexo, do lado oposto
                ao jardim. No anexo temos um auditório para 100 pessoas, uma
                biblioteca especializada, bar industrial e cozinha, junto a
                Galeria de Arte Ramos Cotoco.
              </Typography>
            </Box>
            <img src={images.image5} />
          </Box>
        <Typography>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A primeira apresentação de um
          espetáculo teatral foi no dia 23 de setembro de 1910, feita pela
          Companhia Dramática Lucília Peres, com a peça “O Dote”, de Artur
          Azevedo. Logo em seguida veio a Companhia de Francisco Santos. Existem
          relatos que falam sobre lotação no teatro e outros sobre um
          esvaziamento do público. A maior frequência registrada aconteceu em
          65, com a reencenação feita pela Comédia Cearense da opereta A Valsa
          proibida, com música de Paurilo Barroso e diálogos de Silvano Serra. A
          falta de verbas atrapalhou o teatro de diversas formas ao longo de sua
          história, mas não impediu a sua vida até hoje, recebendo espetáculos e
          públicos dos mais variados e ajudando a manter vida a veia artística
          do nosso povo.
        </Typography>
      </Box>
    </Box>
  );
};
