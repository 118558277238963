import { Box, Typography } from "@mui/material";
import { useStyles } from "./history.style";
import { images } from "assets/img/history/sobrado";

export const History: React.FC = () => {
  const styles = useStyles();

  return (
    <Box sx={styles.container}>
      <Box>
          <Box sx={styles.text1}> 
            <Box>
              <Typography>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Quando pensamos em alguns espaços de destaque em suas devidas localidades, como a 
                Torre Eiffel em Paris - FRA, geralmente não pensamos nas milhares de pessoas que já passaram por aquele local. 
                Entretanto, a passagem de alguns indivíduos acaba ganhando relevância e destaque devido a forma como construímos a
                 memória, por exemplo, muitos conhecem a famosa foto de Adolf Hitler posando em frente a mesma Torre logo após invadir 
                 a França durante a Segunda Guerra Mundial.
              </Typography>
              <Typography>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Ainda como forma de pensar esse mesmo exemplo, milhares de pessoas atravessaram 
                o Rio Nilo, mas apenas quando César, o romano, passou por lá, tivemos um destaque na História. Isso vale para alguns 
                espaços como casas que pertencem ao mesmo século, mas apenas poucas passaram por tombamento, geralmente aquelas que 
                pertenceram a alguma figura pública considerada relevante para o seu período, um padrão quase sempre representado por 
                homens, brancos, ricos, influentes, com grandes cargos de poder passou por esse processo de preservação e restauração, 
                raramente teremos mulheres e outras figuras de grupos excluídos, mas a grande questão é: Por quê?
              </Typography>
            </Box>
            <img src={images.image4} /> 
            
          </Box> 
         <Typography>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Essa reflexão é muito importante a partir de vários pontos: História, 
                sociedade, política, cultura... Devemos trazer esse questionamento para a construção e preservação de novos
                 espaços, não somente em Fortaleza, mas pelo mundo. Enquanto pensamos e lutamos para uma nova construção da História, 
                 vamos conhecer um dos espaços preservados e tombados na capital cearense, o Sobrado do Dr. José Lourenço, local que 
                 funciona atualmente como Centro Cultural no Ceará.
              </Typography>
        <Typography>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Quem passa pela Rua Major Facundo, 154, no Centro de Fortaleza, se depara com uma 
          edificação de três andares datada da segunda metade do século XIX, o Sobrado Dr. José Lourenço, espaço destinado a convivência das artes visuais do Ceará, recebendo atividades diversas voltadas ao campo artístico. A reestruturação, preservação, reforma e tombamento desse espaço são justificadas por alguns motivos, tendo como características principais o seu teor arquitetônico e importância histórica para a cidade, por ter cumprido várias funções, além da relevância social do seu primeiro proprietário, o médico sanitarista Dr. José Lourenço de Castro e Silva.

        </Typography>
        <Typography>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Nominamos de sobrados espaços que buscam receber famílias mais numerosas, aproveitando o espaço do terreno, por menor que seja, para elevar a estrutura, daí o termo “assobradada”, muito comum para caracterizar edificações que comumente chamamos no Ceará de espaços “em cima e embaixo”, referência dada a casas de dois andares. Durante o século XIX, casas assobradadas eram símbolos de poder. Nem todas as famílias tinham moradia, as condições para possuir uma casa de dois andares eram ainda menores. A modernização proposta dentro do século XIX para a capital atraiu muitas famílias abastadas que buscavam seguir os moldes europeus, mas também deixavam muitas marcas de desigualdade. Os sobrados acabavam se destacando pela sua imposição física e arquitetônica, além de cumprirem função comercial para algumas famílias; Nem sempre esse espaço era ocupado por famílias numerosas, mas sim como base comercial; Era comum o térreo funcionar como alguma loja, restaurante, consultório... enquanto os moradores se localizavam no andar superior.

        </Typography>
        <Typography>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;O sobrado do Dr. José Lourenço ganha então ainda mais destaque por ser a primeira construção de três andares do Ceará, construída a época chamada de Rua da Palma, atual Major Facundo. O espaço tinha por função principal cumprir o papel de residência e consultório do sanitarista. O edifício possuía em seu topo uma escultura da qual sobraram apenas vestígios, provavelmente uma figura mitológica, mas não há como comprovar, segundo o professor Liberal de Castro, UFC.

        </Typography>
           <Box sx={styles.text2}> 
            <Box>
              <Typography>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;O primeiro proprietário do espaço, O Dr. José Lourenço, era natural de Aracati, nascido em 1808. A vinda de aracatienses para Fortaleza durante o século XIX se deu com bastante frequência, o crescimento urbano da capital atraiu vários cearenses que buscavam uma melhor condição de vida, incluindo pobres, flagelados e retirantes da secas que acabaram sendo explorados em nome da modernização ao espelho europeu. Era filho do Major Manoel Lourenço da Silva e de Maria do Carmo Sabina e casado com Maria Amália de Brito e Castro, é considerado oficialmente o primeiro médico do Ceará.

              </Typography>
              <Typography>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;O pesquisador Gilmar de Carvalho organizou a obra “O Sobrado do Dr. José Lourenço”, realizada pela Associação dos Amigos do Museu do Ceará, nela José Lourenço de Castro e Silva é descrito como sanitarista, função essa que cumpriu papel importante nas medidas de controle social tomadas durante o século XIX na capital, formado no Rio de Janeiro pela Academia Imperial, em 1834, foi para lá em 1829. Viveu no seu sobrado de três andares, na Rua da Palma; no térreo, manteve a sua clínica onde atendia à chamada moda popular, os andares superiores eram utilizados para viver com a família. Vivia em uma cidade em expansão e a sua casa era vista como símbolo de poder e status. 
              </Typography>
            </Box>
            <img src={images.image2} /> 
           </Box> 
        <Typography>
              A construção do sobrado consta em um intervalo temporal de 1846 – 1876, período em que Fortaleza foi preparada para se consolidar enquanto capital, contribuindo para o esvaziamento de Aracati, que era porto, entreposto comercial e ponto de ligação com Pernambuco.
              </Typography>
           <Box sx={styles.text1}> 
            <Box>
              <Typography>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;O médico passou pela Inspetoria de saúde Pública do Ceará, onde lidou com as epidemias de febre amarela e cólera, além do trabalho na seca de 1878. Foi atuante dentro do Partido Liberal, participando de luta armada. Teve reconhecimento no campo da medicina nacional. Gilmar de Carvalho descreveu o sobrado como um farol de onde irradiava a ordem médica, tendo na figura do seu proprietário um homem dito como ético e respeitável. José Lourenço faleceu no dia 13 de agosto de 1874, em Fortaleza.
              </Typography>
              <Typography>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A partir de 1875, a família do médico passou a alugar o espaço para a recepção de outras funções, entre elas a do Tribunal de Relação do Ceará, equivalente ao atual Tribunal de Justiça do Estado, funcionando por anos no sobrado. Depois abrigou a Prefeitura Municipal de Fortaleza por curto período, a Fênix Caixeral, além de uma oficina de marcenaria, bordel e casa de sombrinhas. Todas as instituições presentes no Sobrado do Dr. José Lourenço deixaram marcas ao longo do tempo, sejam nas suas paredes ou nos jornais e crônicas da época.
              </Typography>
              <Typography>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Luciana Rodrigues de Oliveira, publicou no ano de 2011, nos anais do XXVI Simpósio Nacional de História  - ANPUH, em São Paulo, o artigo intitulado Memórias afetivas: Os bordéis do Sobrado Dr. José Lourenço e seus personagens (1950 – 1970), nele a autora cita as instituições abrigadas no sobrado, como diversas casas comerciais e principalmente os bordéis, que começaram a funcionar ali a partir da década de 1950. Um dos exemplos é a pensão Marajá, conhecida pela beleza das mulheres e por receber clientes ilustres da cidade de Fortaleza na época. Segundo Luciana Rodrigues, não é fácil encontrar documentos sobre o funcionamento dos bordéis no sobrado devido a condenação moral e o senso de puritanismo presente na sociedade do período e até mesmo na atual. Os relatos encontrados estão presentes principalmente em documentos policiais, devido ao grande número de ocorrências e pelo registro das prostitutas que era necessário para o trabalho em bordéis.

              </Typography>
              <Typography>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A tentativa de racionalização do Centro da cidade através de instituições de poder como o cemitério, asilo, hospitais, manicômios... com o objetivo de controlar o crescimento urbano e a tão sonhada modernização não dava conta da pluralidade da cidade, fazendo do centro um espaço de transgressão, luta e resistência, além de ser um local de fácil acesso; esse processo acabou resultando em uma cultura diversificada e ampla pelo centro de Fortaleza. Luciana Rodrigues entrevista pessoas da época, como uma prostituta que veio de Camocim e encontrou nos bordéis uma forma de sobrevivência; frequentadores que demonstraram afetividade pelo espaço, indo além da comercialização do prazer.

        </Typography>
            </Box>
            <img src={images.image1} /> 
          </Box>         
        {/* <SlideIn movement={200}> */}
          {/*  <Box sx={styles.text2}>*/}
            <Box>
              <Typography>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;O sobrado foi protegido pelo tombo estadual, em 2004, pela Secretaria da Cultura do Ceará, segundo a lei n° 9.109 de 30 de julho de 1968. O tombamento levou em consideração aspectos como o mérito arquitetônico do sobrado, a precariedade na sua conservação, uma arquitetura quase em extinção na cidade e a sua referência histórica. O trabalho de restauração foi iniciado em 2006, tendo à frente o arquiteto Domingos Linheiro e auxílio da Escola de Artes e Ofício Thomaz Pompeu, sendo feita pelo Governo do Estado com apoio do Instituto Oi Futuro.

              </Typography>
              <Typography>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A arquitetura do espaço ganha destaque por seu aspecto luxuoso, ornado por florões, azulejos e rosáceas, carregando traços neoclássicos, as fachadas laterais possuem janelas que dão para telhados vizinhos e cornijas com frisos de azulejos o que não era comum para a estrutura de um sobrado unido às suas divisas, segundo os colaboradores do grupo somosvos, que pesquisaram o espaço. A restauração foi um processo difícil devido a muitas práticas arquitetônicas usadas na construção do sobrado terem se perdido com o tempo.

              </Typography>
            </Box>
            {/* <img src={images.image4} /> */}
          {/* </Box> */}
        {/* </SlideIn> */}
        <Typography>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A reinauguração tinha por objetivo tornar o Sobrado José Lourenço sede do Sistema Estadual de Museus, o que resultou em uma espaço voltado para um novo centro cultural na cidade. A data oficial da sua reinauguração é 31 de dezembro de 2007. A primeira exposição foi a 4ª Mostra Cariri das Artes, intitulada “O Cariri Aqui!”, com obras de artistas locais, também houve o lançamento do livro “O Sobrado do Dr. José Lourenço”, citada anteriormente. O Sobrado possui funcionamento de terça à sexta, das 9 às 16:00 e aos sábados de 9:00 às 13:00, com programação diversificada podendo ser acompanhada através dos meios de comunicação do espaço. Vamos continuar criando histórias sobre o Sobrado do Dr. José Lourenço e da cidade de Fortaleza.

        </Typography>
        
      </Box>
    </Box>
  );
};
