import { useState } from 'react';
import { AppRouter } from "./routes";
import { BrowserRouter } from "react-router-dom";
import ScrollToTop from "shared/components/scroll-to-top/ScrollToTop";
import { DefaultLayout } from "shared/components/layouts";
import MenuVisibilityProvider from 'shared/context/MenuContext';
import LightTheme from 'shared/themes/LightTheme';
import { ThemeProvider } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';

export const App = () => {
  return (
    <BrowserRouter>
      <CssBaseline />
      <ThemeProvider theme={LightTheme}>
        <MenuVisibilityProvider>
          <ScrollToTop/>
          <DefaultLayout>
            <AppRouter/>
          </DefaultLayout>
        </MenuVisibilityProvider>
      </ThemeProvider>
    </BrowserRouter>
  );
};
export default App;
