import React from "react";
import { Box, Link, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useStyles } from "./MenuHorizontal.style";
export const MenuHorizontal: React.FC = () => {
  const navigate = useNavigate();
  const styles = useStyles();
  return (
    <Box sx={styles.navLink}>
      <Box onClick={() => navigate("/#")}>
        <Typography variant="h2" component="span">
          início
        </Typography>
      </Box>
      <Box onClick={() => navigate("/monumentlist")}>
        <Typography variant="h2" component="span">
          acervo
        </Typography>
      </Box>
      <Box onClick={() => navigate("/news")}>
        <Typography variant="h2" component="span">
          notícias
        </Typography>
      </Box>
      <Box onClick={() => navigate("/about-us")}>
        <Typography variant="h2" component="span">
          sobre
        </Typography>
      </Box>
    </Box>
  );
};
