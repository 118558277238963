import { fontSize } from "@mui/system";

export const useStyles = () => ({
  root: {
    display: "grid",
    gap: "10px",
    gridTemplateColumns: "1fr 1fr 1fr",
    "@media(max-width: 1250px)": {
      gridTemplateColumns: "1fr 1fr",
    },
    "@media(max-width: 950px)": {
      gridTemplateColumns: "1fr",
    },
  },
  modal: {
    position: "fixed",
    top: "0",
    bottom: "0",
    left: "0",
    right: "0",
    zIndex: "2000",
    backgroundColor: "rgba(0, 0, 0, 0.8)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
  },
  fullscreen: {
    width: "calc(100% - 40px)",
    height: "calc(100% - 40px)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& > img": {
      maxWidth: "80%",
      maxHeight: "100%",
      pointerEvents: "none",
      userSelect: "none",
    }
  },
  icons: {
    position: "fixed",
    cursor: "pointer",
    opacity:" 0.6",
    color: "#fff",
    zIndex: "9999",
  },
  leftArrow:{
    left: "20px"
  },
  rightArrow:{
    right: "20px"
  },
  close:{
    right: "20px",
    top: "20px",
    fontSize: "2em",
  }

});
