
export const useStyles = () =>  ({
  image: {
    height: "100%",
  },
  div: {
    height: "285px",
  },
  content: {
    "& > div": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignContent: "center",
      textAlign: "center",
    },
  },
  name: {
    fontSize: "18px",
  },
});
