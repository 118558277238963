import img02 from "assets/img/gallery/TJA/img02.JPG";
import img03 from "assets/img/gallery/TJA/img03.JPG";
import img04 from "assets/img/gallery/TJA/img04.JPG";
import img05 from "assets/img/gallery/TJA/img05.JPG";
import img06 from "assets/img/gallery/TJA/img06.JPG";
import img07 from "assets/img/gallery/TJA/img07.JPG";
import img08 from "assets/img/gallery/TJA/img08.JPG";
import img09 from "assets/img/gallery/TJA/img09.JPG";
import img10 from "assets/img/gallery/TJA/img10.JPG";
import img11 from "assets/img/gallery/TJA/img11.JPG";
import img12 from "assets/img/gallery/TJA/img12.JPG";
import img13 from "assets/img/gallery/TJA/img13.JPG";
import img14 from "assets/img/gallery/TJA/img14.JPG";
import img15 from "assets/img/gallery/TJA/img15.JPG";
import img16 from "assets/img/gallery/TJA/img16.JPG";
import img17 from "assets/img/gallery/TJA/img17.JPG";
import img18 from "assets/img/gallery/TJA/img18.JPG";
import img19 from "assets/img/gallery/TJA/img19.JPG";
import img20 from "assets/img/gallery/TJA/img20.JPG";

const homeGallery = [
  img02,
  img03,
  img04,
  img05,
  img06,
  img07,
  img08,
  img09,
  img10,
  img11,
  img12,
  img13,
  img14,
  img15,
  img16,
  img17,
  img18,
  img19,
  img20,
];

export default homeGallery;
