import image1 from "./img185.JPG";
import image2 from "./img1587.JPG";
import image3 from "./img1556.JPG";
import image4 from "./img1554.JPG";
import image5 from "./img0087.JPG";
import image6 from "./img0003.JPG";

export const images = {
  image1,
  image2,
  image3,
  image4,
  image5,
  image6,
};
