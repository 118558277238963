import image1 from "./img01.JPG";
import image2 from "./img02.JPG";
import image3 from "./img03.jpeg";
import image4 from "./img04.jpeg";
import image5 from "./img05.jpeg";

export const images = {
    image1,
    image2,
    image3,
    image4,
    image5,
}
