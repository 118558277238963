import { Box } from "@mui/material";
import { MiniMenu } from "shared/components/menu-monument/MiniMenu";
import { TourVirtual } from "shared/components/tour-virtual";
import { History } from "./components";
import { useStyles } from "./pinacoteca.style";
import { CardHowToFind } from "shared/components/card-how-to-find";

export const PINACOTECA: React.FC = () => {
    const styles = useStyles();
    return(
    <Box sx={styles.container}>
      <Box sx={styles.tour}>
        <TourVirtual src="https://repositoriodocumentace.online/pinacoteca-do-ceara/index.htm" />        
        <Box sx={styles.miniMenu}>
          <MiniMenu
            title="Pinacoteca do Ceará"
            history={<History />}
            //gallery={<Gallery image={} />}
            info={
              <CardHowToFind 
              address={{
                name:"R 24 de Maio, 34, Centro, Fortaleza - CE, 60020-000",
                link:"https://goo.gl/maps/zG4HErMMV2EzYEo67" 
              }}
              //guidedTour="Email para agendamento: visitas.bece@idm.org.br"
              accessibility="Abafadores de Ruídos, Audiodescrição, Recursos Táteis, Videoguia em Libras, Cadeira de Rodas, Intérpretes de Libras."
              openingHours="Quinta a Sábado de 12h - 20h | Domingo de 10h - 18h." 
              contacts={{
                email:"contato@pinacotecadoceara.org.br"
              }}
              socialMedia={{
                Instagram:"https://www.instagram.com/pinacotecadoceara/",
                Facebook:"facebook: https://www.facebook.com/pinacotecadoceara",
                YouTube:"https://www.youtube.com/@pinacotecadoceara",
              }}
              />
          }
          />
        </Box>
      </Box>
    </Box>
    );
};