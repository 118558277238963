import img01 from "assets/img/gallery/BECE/img01.jpg";
import img02 from "assets/img/gallery/BECE/img02.jpg";
import img03 from "assets/img/gallery/BECE/img03.jpg";
import img04 from "assets/img/gallery/BECE/img04.jpg";
import img05 from "assets/img/gallery/BECE/img05.jpg";
import img06 from "assets/img/gallery/BECE/img06.jpg";
import img07 from "assets/img/gallery/BECE/img07.jpg";
import img08 from "assets/img/gallery/BECE/img08.jpg";
import img09 from "assets/img/gallery/BECE/img09.jpg";
import img11 from "assets/img/gallery/BECE/img11.jpg";
import img12 from "assets/img/gallery/BECE/img12.jpg";
import img13 from "assets/img/gallery/BECE/img13.jpg";
import img14 from "assets/img/gallery/BECE/img14.jpg";
import img15 from "assets/img/gallery/BECE/img15.jpg";
import img16 from "assets/img/gallery/BECE/img16.jpg";
import img17 from "assets/img/gallery/BECE/img17.jpg";
import img18 from "assets/img/gallery/BECE/img18.jpg";
import img19 from "assets/img/gallery/BECE/img19.jpg";
import img20 from "assets/img/gallery/BECE/img20.jpg";

const homeGallery = [
    img01,
    img02,
    img03,
    img04,
    img05,
    img06,
    img07,
    img08,
    img09,
    img11,
    img12,
    img13,
    img14,
    img15,
    img16,
    img17,
    img18,
    img19,
    img20,
];

export default homeGallery;