import img01 from "assets/img/gallery/ESCOLA/img01.jpg";
import img02 from "assets/img/gallery/ESCOLA/img02.jpg";
import img03 from "assets/img/gallery/ESCOLA/img03.jpg";
import img04 from "assets/img/gallery/ESCOLA/img04.jpg";
import img05 from "assets/img/gallery/ESCOLA/img05.jpg";
import img06 from "assets/img/gallery/ESCOLA/img06.jpg";
import img07 from "assets/img/gallery/ESCOLA/img07.jpg";
import img08 from "assets/img/gallery/ESCOLA/img08.jpg";
import img09 from "assets/img/gallery/ESCOLA/img09.jpg";
import img11 from "assets/img/gallery/ESCOLA/img11.jpg";
import img12 from "assets/img/gallery/ESCOLA/img12.jpg";
import img13 from "assets/img/gallery/ESCOLA/img13.jpg";
import img14 from "assets/img/gallery/ESCOLA/img14.jpg";
import img15 from "assets/img/gallery/ESCOLA/img15.jpg";

const homeGallery = [
    img01,
    img02,
    img03,
    img04,
    img05,
    img06,
    img07,
    img08,
    img09,
    img11,
    img12,
    img13,
    img14,
    img15,
];

export default homeGallery;