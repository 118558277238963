import img01 from "assets/img/gallery/IGREJA-ASSUNCAO/img00.JPG";
import img02 from "assets/img/gallery/IGREJA-ASSUNCAO/img02.JPG";
import img03 from "assets/img/gallery/IGREJA-ASSUNCAO/img03.jpeg";
import img04 from "assets/img/gallery/IGREJA-ASSUNCAO/img04.JPG";
import img05 from "assets/img/gallery/IGREJA-ASSUNCAO/img05.JPG";
import img06 from "assets/img/gallery/IGREJA-ASSUNCAO/img06.JPG";
import img07 from "assets/img/gallery/IGREJA-ASSUNCAO/img07.jpeg";
import img08 from "assets/img/gallery/IGREJA-ASSUNCAO/img08.JPG";
import img09 from "assets/img/gallery/IGREJA-ASSUNCAO/img09.JPG";
import img10 from "assets/img/gallery/IGREJA-ASSUNCAO/img10.JPG";
import img11 from "assets/img/gallery/IGREJA-ASSUNCAO/img11.jpeg";
import img12 from "assets/img/gallery/IGREJA-ASSUNCAO/img13.jpeg";
import img13 from "assets/img/gallery/IGREJA-ASSUNCAO/img14.JPG";
import img14 from "assets/img/gallery/IGREJA-ASSUNCAO/img15.jpeg";

const homeGallery = [
  img01,
  img02,
  img03,
  img04,
  img05,
  img06,
  img07,
  img08,
  img09,
  img10,
  img11,
  img12,
  img13,
  img14,
];

export default homeGallery;
