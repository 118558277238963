import image2 from "./img02.JPG";
import image3 from "./img03.JPG";
import image1 from "./img01.JPG";
import image4 from "./img04.JPG"
import image5 from "./img05.JPG"
import image6 from "./img07.JPG"

export const images = {
    image1,
    image2,
    image3,
    image4,
    image5,
    image6,
}