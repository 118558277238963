import { createTheme } from "@mui/material";

const LightTheme =  createTheme({
    palette: {      
        primary: {
          main: '#ffffff',
          contrastText : "#000",        
        },
        secondary: {
          main: '#000000',
        },
      },
    typography: {
      fontFamily: 'Roboto',       
      h2 : {
        fontFamily : 'Roboto Serif',
      }, 
    },   
  });

  export default LightTheme;