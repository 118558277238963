import { Box, Typography } from "@mui/material";
// import { SlideIn } from "shared/components/slideIn";
import { useStyles } from "./History.style";
import { images } from "assets/img/history/eaotps";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";

export const History: React.FC = () => {
  const styles = useStyles();

  return (
    <Box sx={styles.container}>
      <Box>
          <Box sx={styles.text1}>
            <Box>
              <Typography>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;As mudanças temporais da história carregam inúmeros significados. Vejamos: pessoas que compartilham da mesma idade, sexo, gênero e raça em um mesmo período, mas em países diferentes, apresentam características que as diferem umas das outras. Esse mesmo pensamento aplicado a pessoas que vivem em uma mesma cidade, mas em bairros diferentes, pode nos trazer as mesmas percepções: o local social, histórico do qual fazemos parte aparece em quem somos.

              </Typography>
              <Typography>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Esse debate está presente na história já faz algum tempo, basta observarmos a obra do historiador francês Marc Bloch Apologia da História ou o ofício do historiador, publicada pela primeira vez em 1949, por Lucien Febvre, após a morte de Bloch, em 1944, fuzilado por nazistas, onde é feita uma discussão profunda sobre o papel da história enquanto ciência e a função do historiador. Lilia Schwarcz escreve a apresentação à versão brasileira da obra, de 2001, iniciando com um provérbio árabe que diz: “os homens se parecem mais com a sua época do que com os seus pais”, a reflexão sobre o velho provérbio nos ajuda a compreender melhor o que tratamos logo no início, nós seres humanos carregamos conosco o tempo do qual fazemos parte; tempo aqui é muito mais do que anos e datas, mas cultura, política, sociedade e tudo o mais que implica na própria produção humana. 

              </Typography>
            </Box>
            <img src={images.image2} />
          </Box>
        <Typography>
        Isso explica por que duas pessoas de aspectos físicos semelhantes podem ser tão diferentes de acordo com o contexto, o ser humano medieval não é o mesmo que vemos hoje, mas deixa os seus rastros.
        </Typography>
        <Typography>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Assim como as pessoas, os espaços também carregam significados ao longo da história, sendo mutáveis. Nós atribuímos e dotamos as coisas e espaços de sentido, fazendo com que a alteração do contexto também modifique a forma como enxergamos e vivemos determinados locais. Estamos aqui nos referindo a prédios, teatros, cinemas... e até mesmo de bairros inteiros. O dispositivo cultural que abordamos aqui é a Escola de Artes e Ofícios Thomas Pompeu Sobrinho, conhecida pela sigla EAOTPS. A escola está localizada atualmente na Avenida Francisco Sá, 1801, no Bairro Jacarecanga, anteriormente conhecido como Bairro Fernandes Vieira.

        </Typography>
        <Typography>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;O prédio onde está localizada a escola é a antiga casa de Thomas Pompeu, o espaço possui uma imposição arquitetônica que logo chama a atenção, característica das moradias da burguesia de Fortaleza entre os séculos XIX e XX que aprofundaremos mais a frente, juntamente com a biografia de Pompeu Sobrinho, mas o que queremos trazer como reflexão neste momento é: o Bairro Jacarecanga carrega, dentro do século XXI, a imagem de um espaço elitizado?

        </Typography>
        <Typography>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Temos a possibilidade do não como resposta para a indagação. A Jacarecanga é um bairro de nuances, misturando características de vários perfis de moradores e histórias, desde grandes edifícios para moradia até zonas periféricas. Algo diferente do que observamos no século XX. O bairro surgiu em 1915, onde até meados de 1940 era conhecido por receber a elite fortalezense, que traduzia os costumes europeus para a capital cearense, na busca da construção de uma identidade distintiva. A ideia era se afastar do centro da cidade, que ao longo do século XX passa a ser visto como um espaço ocupado pelo comércio, pela classe trabalhadora e por flagelados das secas. Os arredores do centro passam por uma ocupação da elite local incentivados pelo fim da seca, a retomada comercial do Ceará e a facilidade de locomoção devido aos bondes elétricos, sistema viário e mais tarde ônibus e carros movidos a gasolina.

        </Typography>
          <Box sx={styles.text2}>
            <Box>
              <Typography>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A estrada de ferro trouxe ligação a outros bairros, passando por zonas industriais, valorizando terrenos e trazendo investimento de empresas e a construção de casas. O modelo arquitetônico traz consigo notória inspiração nos grandes centros urbanos do Brasil e também da Europa, um clima ainda muito próximo da Belle Époque, mas carregando também diversos traços locais. O bairro passa a ser visto como elegante, além de atrativo para essa elite devido a proximidade de outros espaços de sociabilidade, como o próprio Passeio Público e escolas, como o Liceu do Ceará.

              </Typography>
              <Typography>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Dentre as moradias erguidas nesse contexto, temos a casa de Thomaz Pompeu Sobrinho, nascido em Fortaleza no dia 16 de novembro de 1880, foi engenheiro, antropólogo, escritor e uma das figuras intelectuais mais importantes da sua época, sendo membro e presidente da ACL – Academia Cearense de Letras e do Instituto do Ceará. Estudou no Liceu do Ceará e formou-se em engenharia pela Escola de Minas de Ouro Preto. Participou da Comissão do Açude de Quixadá e da Inspetoria de Obras contra as Secas, foi um dos fundadores da Escola Prática de Agricultura de Quixadá, também fundador e professor da Escola de Agronomia do Ceará; também fundou e dirigiu o Instituto de Antropologia da Universidade Federal do Ceará, em 1958, além de ter sido presidente do Conselho Nacional de Economia.

              </Typography>
            </Box>
            <img src={images.image3} />
          </Box>
        <Typography>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Em 1929, fez o mando de construção da Casa Thomaz Sobrinho, espaço que lhe serviu de residência até a sua morte no Rio de Janeiro, no dia 09 de novembro de 1967. A casa demorou cinco anos para ser concluída, sendo finalizada em 1929. Possui o estilo da Art Nouveau italiana, movimento que vigorou entre os séculos XIX e XX, buscando acompanhar e atender as necessidades da sociedade industrial, utilizando novos materiais e tecnologias, com muita exuberância, novas formas e “elegância”, além de uma decoração mais exótica e elaborada. A fachada da casa possui um portal de concreto, um dos vários artigos de luxo encontrados no espaço; o piso é de madeira, preenchendo uma sala enorme e com várias decorações, trazendo muitos contrastes de temporalidade.

        </Typography>
          <Box sx={styles.text1}>
            <Box>
              <Typography>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A casa é cercada por imensos jardins. Nos fundos do espaço é possível encontrar um dos maiores símbolos de riqueza: um porão tenso como acesso uma escada de concreto, material que ainda não era fabricado no Brasil e sem especificidade de como foi construído, muito provavelmente a escada foi comercializada já pronta, vindo de outro país. No mesmo porão é possível ter acesso a estrutura da casa erguida de colunas de concreto e trilhos de bonde.

              </Typography>
              <Typography>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;No primeiro andar temos um vasto espaço com vista para os jardins; no segundo andar ficavam os quartos da família e casinhas de asseio externas à casa; o banheiro, dentro da casa no segundo andar, conta com outro artigo de luxo: uma pia de louça vinda de Liverpool. A casa também conta com uma varanda que dá acesso a uma pequena torre com vista para o mar.
              </Typography>
              <Typography>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Após a morte de Thomaz Pompeu Sobrinho, a família renunciou à casa, alegando as dificuldades de manutenção que requeriam altos gastos, sendo um espaço muito grande para se gerenciar. A casa de três pavimentos, onde no primeiro andar temos o porão e existiam quartos de empregados; o segundo com sala de estar, quarto dos proprietários e de hóspedes, banheiro, além da cozinha e no terceiro outra cozinha, banheiros e quartos, passou para a custódia do Governo do Estado do Ceará, em 1968.

              </Typography>
            </Box>
            <img src={images.image5} />
          </Box>
        <Typography>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Trinta e quatro anos depois, em 2002, o espaço passa a funcionar como a  Escola de Artes e Ofícios, com o objetivo de formar e capacitar pessoas no processo de restauração e conservação do patrimônio cultural material, além de valorizar e recuperar o Patrimônio Cultural imaterial do Ceará. A casa foi restaurada em 2001 por 60 jovens que formaram a primeira turma da escola. O espaço busca manter diálogo com a sociedade, através das oportunidades e trabalhos realizados. O espaço foi entregue à população no dia 18 de dezembro de 2002 para sediar o Centro de Restauro do Ceará, após 4 anos, depois de receber todo o equipamento e estrutura necessária, no dia 15 de maio de 2006, tem a sua aula inaugural como Escola de Artes e Ofícios, passando a ser a Escola de Artes e Ofícios Thomaz Pompeu Sobrinho (EAOTPS).

        </Typography>
              <Typography>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A escola é vinculada à Secretaria de Cultura do Estado Ceará e gerenciada pelo Instituto Dragão do Mar. Lá são ofertados cursos, minicursos e oficinas voltados para a conservação e restauro de bens culturais, além de artesanato das mais variadas formas voltado preferencialmente para jovens de escolas públicas ou em situação de vulnerabilidade. Muitos dos formados pela EAOTPS trabalharam recentemente no restauro e inauguração de dispositivos culturais da cidade, como a Estação das Artes, no centro de Fortaleza. O espaço além da sua importância histórica, carrega uma relevante ação social, podendo ser visitado por ambos os motivos.
              </Typography>
      </Box>
    </Box>
  );
};
