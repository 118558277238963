import custodio from "assets/img/card-about-us/cust.png";
import ezequiel from "assets/img/card-about-us/ezequiel.jpeg";
import mylene from "assets/img/card-about-us/mylene.jpeg";
import paulo from "assets/img/card-about-us/paulo.jpeg";
import maycon from "assets/img/card-about-us/maycon.jpeg";
import ivanio from "assets/img/card-about-us/ivanio.jpeg";
import barbalho from "assets/img/card-about-us/alexandreBarbalho.jpeg";
import lazaro from "assets/img/card-about-us/lazaro.jpeg";
import gretha from "assets/img/card-about-us/gretha.jpeg";
import miguel from "assets/img/card-about-us/miguel.jpeg";
import leandro from "assets/img/card-about-us/leandro.jpeg";
import alexandre from "assets/img/card-about-us/alexandre.jpeg";
import chico from "assets/img/card-about-us/chico.jpg";
import userDefault from "assets/img/user.png";

export const equipe  = [
    {
        name : "Custódio Almeida",
        cargo : "Coordenador Geral",
        image : custodio,
        lattes : "http://lattes.cnpq.br/2127408625276716",
    },
    {
        name : "Esequiel Mesquita",
        cargo : "Coordenador",
        image : ezequiel,
        lattes : "http://lattes.cnpq.br/3585259598740974",
    },
    {
        name : "Mylene Vieira ",
        cargo : "UFC",
        image : mylene,
        lattes : "http://lattes.cnpq.br/8396115807397120",
    },
    {
        name : "Eugênio Moreira",
        cargo : "UFC",
        image : userDefault,
        lattes : "http://lattes.cnpq.br/4126078450140121",
    },
    {
        name : "Alexandre Arruda",
        cargo : "UFC",
        image : userDefault,
        lattes : "http://lattes.cnpq.br/9877991623494574",
    },
    { 
        name : "Paulo Sá",
        cargo : "UECE",
        image : paulo,
        lattes : "http://lattes.cnpq.br/4716488093888781",
    },
    {
        name : "Renan Paulo",
        cargo : "UFC",
        image : userDefault,
        lattes : "http://lattes.cnpq.br/6159219802464348",
    },
    {
        name : "Maycon Bessa",
        cargo : "UFC",
        image : maycon,
        lattes : "http://lattes.cnpq.br/3443074253157325",
    },
    {
        name : "Alexandre Barbalho",
        cargo : "UECE",
        image : barbalho,
        lattes : "http://lattes.cnpq.br/7017826279838774",
    },
    {
        name : "Miguel Castro",
        cargo : "UFC",
        image : miguel,
        lattes : "http://lattes.cnpq.br/5641112041037627",
    },
    {   
        name : "Alexandre Vale",
        cargo : "UFC",
        image : alexandre,
        lattes : "http://lattes.cnpq.br/3358019586928320",
    },
    {
        
        name : "Gretha Maia",
        cargo : "UFC",
        image : gretha,
        lattes : "http://lattes.cnpq.br/9623562350572470",          
    },
    {
        name : "José Carlos Filho",
        cargo : "UFC",
        image : lazaro,
        lattes : "http://lattes.cnpq.br/1338514748652744",
    },
    {
        name : "Ivânio Lopes",
        cargo : "UFCA",
        image : ivanio,
        lattes : "http://lattes.cnpq.br/6634349127128987",
    },
    {
        name : "Leandro de Jesus",
        cargo : "UFC",
        image : leandro,
        lattes : "http://lattes.cnpq.br/1892221694481474",
        
    },
    {
        name : "Rafaela Braga",
        cargo : "GTA Levantamentos",
        image : userDefault,
        lattes : "http://lattes.cnpq.br/2543464139551927",        
    },
    {
        name : "Selma Lima",
        cargo : "SECULT",
        image : userDefault,
        lattes : "http://lattes.cnpq.br/2227422922940445",
    },
    {
        name : "Rachel Weyne",
        cargo : "SECULT",
        image : userDefault,
        lattes : "http://lattes.cnpq.br/7273506599394108",
    },
    {
        name : "Selene Silveira",
        cargo : "SECULT",
        image : userDefault,
        lattes : "http://lattes.cnpq.br/0636207266875598",      
    },
    {
        name : "Ernesto Gadelha",
        cargo : "Coordenador da SECULT",
        image : userDefault,
        lattes : "http://lattes.cnpq.br/9263552867484818",  
    },
    {
        name : "Thalita Pinheiro",
        cargo : "UFC",
        image : userDefault,
        lattes : "https://lattes.cnpq.br/6923944299668081",   
    },
    {
        name : "Moisés Almeida",
        cargo : "UFC",
        image : userDefault,
        lattes : "http://lattes.cnpq.br/6293764161848283",
    },
    {
        name : "Lucas Alves",
        cargo : "UFC",
        image : chico,
        lattes : "https://www.linkedin.com/in/francisco-lucas-6b5741252/", 
    },
];