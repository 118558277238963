import { createTheme } from "@mui/material";

export const useStyles = () => ({
    navLink: {
        display: "flex",
        "& > div": {
          display: "flex",
          position: "relative",
          cursor: "pointer",    
          alignItems: "center",
          justifyContent:"center",
          width: "7rem",
          "&:after": {    
            background: "none repeat scroll 0 0 transparent",
            bottom: 0,
            content: "''",
            display: "block",
            height: "2px",
            left: "50%",
            position: "absolute",
            backgroundColor: "#000",
            transition: "width 0.3s ease 0s, left 0.3s ease 0s",
            width: 0,
          },
          "&:hover:after" : {
            width: "100%",
            left: "0",
          },
          "& span" :{
              margin : "1rem",
              fontSize: "1.4rem",              
          },
        },  
    },    
});