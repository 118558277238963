import { Box, Typography } from "@mui/material";
import { useStyles } from "./history.style";
//import { images } from "assets/img/history/MIS";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";

export const History: React.FC = () => {
  const styles = useStyles();

  return (
    <Box sx={styles.container}>
      <Box>
          <Box sx={styles.text1}>
            <Box>
              <Typography>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A luta por espaços de representatividade artística é algo que acompanha as 
                transformações humanas ao longo da história. Em nossa jornada pelo planeta, construímos formas de representar o nosso 
                cotidiano, desde pinturas rupestres e cerâmicas até apresentações digitais em alta resolução, cada uma carregando as 
                marcas históricas da sua sociedade.
              </Typography>
              <Typography>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Desde as primeiras civilizações humanas a arte aparece como elemento crucial de 
                identificação e representação, passando pela caça, coleta e rituais, até o desenvolvimento do Estado e surgimento das 
                primeiras cidades em seu formato hierarquizado. Os povos do Egito Antigo e Mesopotâmia passam a representar os seus 
                governantes, deuses e deusas, além do dia a dia, seja através de pinturas ou esculturas. Gregos e romanos, sendo a 
                arte romana resultado da absorção da cultura grega, representavam os corpos humanos buscando a perfeição, as divindades 
                em seus formatos humanos ganhavam os espaços das cidades.
              </Typography>
            </Box>
            {/* <img src={images.image6} /> */}
          </Box>
        <Typography>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;No período medieval, a razão perde espaço para a fé, que ganha destaque também na arte, 
          em pinturas, esculturas e nos vitrais espalhados por templos religiosos. O renascimento tenta trazer consigo uma retomada da 
          razão e de seus elementos clássicos, detalhando de forma minuciosa e precisa cada peça produzida. São muitas formas de 
          produzir e escolas que surgem com as suas próprias percepções sobre o representar e o que é a arte.
        </Typography>
        <Typography>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Com o desenvolvimento do mundo moderno através da organização, das leis e do próprio 
          Estado, os meios de produção e tecnologias a arte e a concepção da compreensão do mundo através do artístico vão perdendo 
          espaço. Em um mundo cada vez mais individual dentro da sociedade capitalista, valores coletivos vão se perdendo e os 
          artistas se tornam cada vez mais subjetivos e menos coletivos, já que não havia uma conexão plural com o público.
        </Typography>
        <Typography>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A arte passa a ser inserida em um novo contexto histórico, existe um afastamento do 
          religioso e aproximação com a nobreza e os mecenas, temos o antagonismo de novas classes sociais: burguesia e proletariado. 
          A arte agora é um produto industrial, transformada em mercadoria e em produto de distinção social, mas também de resistência 
          e sobrevivência.
        </Typography>

          <Box sx={styles.text2}>
            <Box>
              <Typography>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Na busca de encontrar alternativa para além da expressão técnica, artistas 
                passaram a produzir sobre experiências oníricas, instintivas, olhar cada vez mais para o interior, alguns criam uma 
                linguagem própria como forma de expressão. A revolta também passa a se fazer presente nesse contexto. O surrealismo, 
                por exemplo, sugere a luta contra a razão, fazendo prevalecer à imaginação, a arte como rejeição do mundo.
              </Typography>
              <Typography>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;O dadaísmo, por sua vez, propõe a negação da própria arte. Objetos do cotidiano 
                civilizatório são retirados de seu contexto como forma de sátira. Walter Benjamin, ao escrever sobre a cultura de 
                massas, crítica à produção em larga escalar que matava o sentido artístico, a arte seria apenas espetáculo e não mais 
                contemplativa. O ponto positivo, segundo Benjamin, dos avanços da modernidade estaria atrelado ao desenvolvimento 
                técnico de aspectos como o cinema, a fotografia e a sonoridade, que poderiam ajudar na democratização da arte. Guy 
                Debord produziu uma obra falando sobre a espetacularização da sociedade moderna, chamada: A sociedade do Espetáculo, 
                também levando em consideração os impactos artísticos no cinerário moderno.
              </Typography>
              <Typography>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A Escola de Frankfurt, com Max Horkheimer e Theodor Adorno teve uma produção de 
                análise sobre a cultura nas sociedades modernas, o que chamaram de Indústria Cultural, uma produção lógica e 
                industrial que visa unicamente o lucro e o consumo. A padronização é valorizada a partir da premissa daquilo que é 
                lucrativo, não necessariamente reflexivo ou apreciativo.
              </Typography>
            </Box>
            {/* <img src={images.image7} /> */}
          </Box>
        <Typography>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Por fim, temos o francês Henri Lefebvre, para ele a partir do momento em que a arte se 
          desligou da representação do belo, passamos a buscar algo que substituísse a beleza, foram muitas tentativas, como a 
          política, a forma, a destruição, os sonhos ou até o caos. A arte é um conceito em constante transformação, assim como a 
          humanidade e as suas sociedades.
        </Typography>
          <Box sx={styles.text1}>
            <Box>
              <Typography>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Agora, pensemos também por outro lado na importância de espaços para a 
                preservação e contato com a arte, por mais que exista uma diversificação das possibilidades de apreciação, 
                estudo e observação, seja por meio virtual ou até em locais abertos e públicos, algumas obras exigem maior 
                cuidado e atenção, além da possibilidade de concentração em um mesmo espaço.
              </Typography>
              <Typography>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;As pinacotecas possuem o papel de receber e exibir acervos nacionais e 
                internacionais, valorizando principalmente a cultura local. O nome vem do latim pinacotheca, derivado do grego 
                pinacothêkê, que significa pinax: akos quadro e et thêké caixa. No Brasil, temos como destaque a pinacoteca de 
                São Paulo; desde de dezembro de 2022, o Ceará conta com a sua própria pinacoteca, localizada em Fortaleza, no 
                centro da cidade, na antiga estação João Felipe, sendo um museu integrante da Rede Pública de Equipamentos da 
                Secretaria da Cultura do Estado do Ceará (Secult – CE).
              </Typography>
            </Box>
            {/* <img src={images.image3} /> */}
          </Box>
        <Typography>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Espaços como a pinacoteca surgem da necessidade e também da luta pela valorização da 
          arte e da cultura, local, nacional e mundial, valorizando, enriquecendo e contribuindo para a formação de um povo. 
          O próprio site da Pinacoteca do Ceará destaca a criação do local como resultado de uma luta história, que remonta aos 
          anos 1940, quando artistas locais reivindicavam um espaço para exposição e valorização da arte no Estado.ônica
          harmonioso.
        </Typography>
          <Box sx={styles.text2}>
            <Box>
              <Typography>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;O debate pela construção de uma pinacoteca continua na década de 1960, 
                com a criação da Secretaria de Cultura e também do Conselho de Cultura, mas a ditadura civil-militar ataca de 
                forma violenta a produção e valorização da arte no país. Com a retomada democrática o debate e a luta pela arte 
                ganham novo fôlego, após um longo período, em 2019, temos o projeto de criação do Complexo Cultural Estação das 
                Artes, onde era alocada a Estação João Felipe, englobando também áreas vizinhas, nesse espaço teríamos o Mercado 
                AlimentaCE, a Estação das Artes, o Centro de Designer, a Pinacoteca do Ceará, além das novas sedes da Secretaria 
                de Cultura do Estado do Ceará e do Instituto Nacional do Patrimônio Histórico e Artístico (Iphan).
              </Typography>
              <Typography>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Segundo o Mapa Cultural do Ceará, espaço da própria Secretaria de Cultura: 
                “A Pinacoteca do Ceará é um museu público com a missão de salvaguardar, preservar, pesquisar e difundir a coleção de 
                arte do Governo do Estado, além de realizar ações formativas com artistas, comunidade escolar, famílias, movimentos 
                sociais, organizações não-governamentais e demais profissionais do campo das artes e da cultura. As atividades irão 
                promover a inclusão de todos os públicos, com ações específicas e afirmativas voltadas às pessoas com deficiência, 
                população negra, população LGBTQIA+ e demais grupos vulneráveis ou invisibilizados. Pensada como um espaço de 
                experimentação, pesquisa e reflexão, a Pinacoteca do Ceará constitui-se como museu-ateliê, promovendo o diálogo entre
                 arte e educação a partir da dinâmica de práticas artísticas”. A programação da Pinacoteca é diversificada e gratuita, 
                 ocorrendo de quinta a sábado, de 12h às 20h e domingo, de 10h às 18h.
              </Typography>
            </Box>
            {/* <img src={images.image4} /> */}
          </Box>
        <Typography>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A pinacoteca é um espaço com muitos significados de importância, não somente pela 
          preservação artística, mas pela vivacidade e vida cultural da cidade e do Ceará. Ela traz consigo novas cores ao centro, 
          incorporando ao cotidiano local atividades que sempre existiram, mas que nem sempre possuíam a valorização que lhes cabia. 
          Um símbolo da riqueza da cultura local e uma vitória para a arte e patrimônio.
        </Typography>
      </Box>
    </Box>
  );
};