// import { makeStyles } from "@mui/styles";

export const useStyles = () => ({
  container: {
    // backgroundColor: "#5A2E85",
    display: "flex",
    flexDirection: "column",
  },
  texts: {
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
    flexDirection: "column",
    padding: "120px 0px",
    // backgroundColor: "#5A2E85",
    // color: "#FFF",
  },
  title: {
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
    margin: "15px",
    textAlign: "center",
    "& p": {
      fontSize: "40px",
      padding: "0px 5px",
      borderBottom: "1px solid",
      fontWeight: "lighter",
    },
  },
  subtitle: {
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
    alignSelf: "center",
    maxWidth: "600px",
    margin: "0px 10%",
    "& p": {
      fontSize: "20px",
      fontWeight: "lighter",
      textAlign: "center",
    },
  },
  monuments: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    flexWrap: "wrap",
    width: "100%",
    height: "100%",
    margin: "80px 0px",
  },
  imgEffect: {
    transition: '0.5s',
  },
  imgLstContainer: {
    cursor: "pointer",
    overflow: "hidden",
    position: "relative",
    '&:hover img': {
      filter: 'brightness(50%)',
      transform: 'scale(1.2)',
      transition: '0.5s',
    },
  },  
  imgCaption: {
    position: "absolute",
    bottom: "0px", /* position the caption at the bottom of the container */
    left: "10px",
    padding: "10px",
    fontSize: "1.5rem",
    fontAlign: "center",
    fontWeight: "bold",
    color: "white",
    // textStroke: "2px black",
    fontFamily: "Roboto"

  },
  imgCaptionSoon: {
    position: "absolute",
    top: "40%",
    left: "40%",
    backgroundColor: "#FFFFFFaa",
    color: "black",
    paddingLeft: "10px",
    paddingRight: "10px",
    fontWeight: "bold",
    fontSize: "1.5em",
    fontFamily: "Roboto",
    textAlign: "center",
  },
  imggradient: {
    position: 'absolute',
    bottom: "0px", /* position the caption at the bottom of the container */
    left: "0px",
    backgroundImage: 'linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1))',
    height: '70%',
    width: '100%',
  },  

});
