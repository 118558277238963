import { useEffect, useContext } from "react";
import { Box, Typography } from "@mui/material";
import { ImageList, ImageListItem } from "@mui/material/";
import { useStyles } from "./MonumentList.style";
import { images } from "assets/img/cards";
import { useTheme, useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { MenuVisibilityContext } from "shared/context";

const itemData = [
  {
    img: images.image1,
    link: "tja",
    title: "Theatro José de Alencar",
    rows: 1,
    cols: 1,
  },
  {
    img: images.image3,
    link: "bece",
    title: "Biblioteca Pública Estadual do Ceará (BECE)",
    rows: 1,
    cols: 1,
  },
  {
    img: images.image7,
    link: "mis",
    title: "Museu da Imagem e do Som",
    rows: 1,
    cols: 1,
  },
  {
    img: images.image8,
    link: "eaotps",
    title: "Escola de Artes e Ofícios Thomaz Pompeu Sobrinho",
    rows: 1,
    cols: 1,
  },
  //{ img: images.image5, title: "Centro Dragão do Mar de Arte e Cultura", rows: 1, cols: 1 },
  {
    img: images.image6,
    link: "escola-gastronomia",
    title: "Escola de Gastronomia Social Ivens Dias Branco",
    rows: 1,
    cols: 1,
  },
  {
    img: images.image2,
    link: "sobrado",
    title: "Sobrado Dr. José Lourenço",
    rows: 1,
    cols: 1,
  },
  { img: images.image4, 
    link: "cine",
    title: "Cineteatro São Luiz", 
    rows: 1, 
    cols: 1 
  },
  {
    img: images.image9,
    link: "pinacoteca",
    title: "Pinacoteca do Ceará",
    rows: 1,
    cols: 1,
  },
  {
    img: images.image10,
    link: "igreja-assuncao",
    title: "Igreja de Nossa Senhora da Assunção",
    rows: 1,
    cols: 1,
  },
];

function srcset(image: string, size: number, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${
      size * rows
    }&fit=crop&auto=format&dpr=2 2x`,
  };
}

export const MonumentList: React.FC = () => {
  const smDown = useMediaQuery(useTheme().breakpoints.down("sm"));
  const mdDown = useMediaQuery(useTheme().breakpoints.down("md"));
  const lgDown = useMediaQuery(useTheme().breakpoints.down("lg"));

  const cols = smDown ? 1 : mdDown ? 2 : lgDown ? 3 : 4;
  const rowHeight = lgDown ? 250 : 200;
  const styles = useStyles();
  const navigate = useNavigate();
  const theme = useTheme();

  const menuProperties = useContext(MenuVisibilityContext);

  useEffect(() => {
    if (false == menuProperties.isMenuVisible) {
      menuProperties.toggleMenuVisibility();
    }
  }, []);

  return (
    <Box sx={styles.container}>
      <Box sx={{ ...styles.texts, background: theme.palette.primary.main }}>
        <Box sx={styles.title}>
          <Typography variant="h2">ACERVO DIGITAL</Typography>
        </Box>
        <Box sx={styles.subtitle}>
          <Typography>
            Descubra mais sobre os espaços que compõem a Rede Pública de
            Equipamentos Culturais da Secult – CE (RECE) conferindo imagens dos
            patrimônios digitalizados, suas histórias, fotos, vídeos, visitas
            virtuais e mais informações.
          </Typography>
        </Box>
      </Box>
      <Box sx={{ margin: "0.5rem" }}>
        <ImageList
          sx={{ width: "100%", height: "auto" }}
          variant="quilted"
          gap={8}
          cols={cols}
          rowHeight={rowHeight}
        >
          {itemData.map((item) => (
            <ImageListItem
              sx={styles.imgLstContainer}
              key={item.img}
              cols={cols !== 1 ? item.cols : 1}
              rows={cols !== 1 ? item.rows : 1}
              onClick={() => {
                if (item.link) navigate(`../monument/${item.link}`);
              }}
            >
              <img
                style={styles.imgEffect}
                {...srcset(item.img, 200, item.rows, item.cols)}
                alt={item.title}
                loading="lazy"
              />
              <Box sx={styles.imggradient} />
              <Typography sx={styles.imgCaption}>{item.title}</Typography>
              {!item.link ? (
                <Box sx={styles.imgCaptionSoon}>
                  <Typography>EM BREVE</Typography>
                </Box>
              ) : (
                ""
              )}
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
    </Box>
  );
};
