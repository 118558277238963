export const useStyles = () => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  background: {
    display: "flex",
    position: "relative",
    width: "100wv",
    height: "100vh",
    alignItems: "center",
    justifyContent: "center",
    "& video": {
      position: "absolute",
      zIndex: "-1",
      width: "100%",
      height: "100%",
      filter: "opacity(.6)",
      objectFit: "cover",
    },
  },
  imggradienttop: {
    position: "absolute",
    top: "0px" /* position the caption at the bottom of the container */,
    left: "0px",
    backgroundImage:
      "linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0))",
    height: "15%",
    width: "100%",
  },
  button: {
    width: "70px",
    height: "70px",
    borderRadius: "50%",
    backgroundColor: "transparent",
    position: "absolute",
    bottom: "10%",
    border: "2px solid #000000",
    color: "#000000",
    "&:hover": {
      backgroundColor: "#000000",
      "& a": {
        color: "#FFFFFF",
      },
    },
    "& a, div": {
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: "#000000",
    },
  },
  logoInicial: {
    width: "100%",
  },
  title: {
    position: "absolute",
    display: "flex",
  },
  text: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    margin: "0px 0px 20px 0px",
    ["@media (max-width:900px)"]: { flexDirection: "column" },
    "& div": {
      width: "100%",
    },
    "& > div": {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      padding: "15px",
      ["@media (min-width:900px)"]: {
        flexDirection: "row",
      },
    },
  },
  main: {
    display: "flex",
    alignItems: "center",
    alignContent: "center",
    justifyContent: "flex-start",
    flexDirection: "column",
    "& img": {
      width: "100%",
      padding: "1rem",
    },
  },
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    textAlign: "justify",
    marginTop: "20px",
    "& p": {
      fontSize: "22px",
    },
  },
  imgEffect_visible_for_mobile: {
    filter: "brightness(50%)",
    transform: "scale(1.2)",
    transition: "0.5s",
  },
  imgLstContainer: {
    cursor: "pointer",
    borderRadius: "1rem",
    overflow: "hidden",
    position: "relative",
    "&:hover img": {
      // filter: 'brightness(50%)',
      transform: "scale(1.2)",
      transition: "2s",
    },
  },
  imgCaption: {
    position: "absolute",
    bottom: "0px" /* position the caption at the bottom of the container */,
    left: "10px",
    padding: "10px",
    fontSize: "1.5rem",
    fontAlign: "center",
    fontWeight: "bold",
    color: "white",
    // textStroke: "2px black",
    fontFamily: "Open Sans, sans-serif",
  },
  imgCaptionSoon: {
    position: "absolute",
    top: "40%",
    left: "20%",
    right: "20%",
    backgroundColor: "#FFFFFFaa",
    color: "black",
    paddingLeft: "2rem",
    paddingRight: "2rem",
    // fontWeight : "bold",
    // fontSize: "1.5em",
    // fontFamily : "Open Sans",
    textAlign: "center",
  },
  imggradientbottom: {
    position: "absolute",
    bottom: "0px" /* position the caption at the bottom of the container */,
    left: "0px",
    backgroundImage:
      "linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1))",
    height: "30%",
    width: "100%",
    filter: "opacity(1)",
  },
  texts: {
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
    flexDirection: "column",
    padding: "3rem 0rem",
    // backgroundColor: "#5A2E85",
    // color: "#FFF",
  },
  titlepatrimonio: {
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
    margin: "15px",
    "& p": {
      // fontSize: "40px",
      padding: "0px 5px",
      borderBottom: "1px solid",
      // fontWeight: "bold",
    },
  },
  subtitle: {
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
    alignSelf: "center",
    maxWidth: "600px",
    margin: "0px 10%",
    "& p": {
      fontSize: "1.5rem",
      fontWeight: "lighter",
      textAlign: "justify",
    },
  },
  link: {
    textAlign: "center",
    "& a": {
      fontWeight: "bold",
      padding: "1rem",
      color: "#000000de",
    },
  },
});
