import image2 from "./img02.jpg";
import image3 from "./img03.jpg";
import image4 from "./img05.jpg";
import image5 from "./img14.jpg";
import image1 from "./img16.jpg";
import image6 from "./img08.jpg";

export const images = {
    image1,
    image2,
    image3,
    image4,
    image5,
    image6,
}