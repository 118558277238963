import { Box, Typography } from "@mui/material";
import { useStyles } from "./History.style";
import { images } from "assets/img/history/igreja-assuncao";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";

export const History: React.FC = () => {
  const styles = useStyles();

  return (
    <Box sx={styles.container}>
      <Box>
          <Box sx={styles.text1}>
            <Box>
              <Typography>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A invasão do território cearense possui as suas particularidades dentro do 
                período colonial brasileiro. A época chamada de Siará Grande, a capitania, assim como outras do espaço 
                brasileiro, não chamou a atenção dos invasores europeus devido a falta de atrativos de acordo com interesses 
                mercantilistas, que buscavam o ouro, a prata e outros metais preciosos.
              </Typography>
              <Typography>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A região atualmente conhecida como nordeste, durante o período colonial 
                teve como principal destaque a capitania de Pernambuco, que mesmo não possuindo a presença do ouro, se mostrou 
                muito rentável aos invasores devido a possibilidade de cultivo da cana-de-açúcar, utilizada para a produção do
                 açúcar, do melaço e de bebidas. Pernambuco devido ao seu destaque econômico, social e cultural passa então a 
                 gerenciar outros territórios da região, incluindo o Ceará.
              </Typography>
            </Box>
            { <img src={images.image2} />}
          </Box>
              <Typography>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Segundo Assis e Sampaio, 2012: 
                <span style={styles.quotes}>
                <FormatQuoteIcon sx={styles.quoteOpenIcon} />
                <span> 
                  o Ceará, socialmente, foi constituindo-se como 
                  peculiaridade econômica e de homens políticos envolvidos com as atividades de subsistência, principalmente a criação de 
                  gado, o comércio de peles e de carnes secas (charques). A produção agrícola era baseada na cultura do milho, do feijão,
                  da mandioca, na extração da cera da carnaúba, na produção de algodão, café e no plantio de cana para a produção de 
                  rapadura. 
                </span>
                <FormatQuoteIcon sx={styles.quoteCloseIcon} />
                </span>
                O Charque citado pelos autores acaba se tornando o primeiro grande produto produzido no Ceará, atraindo
                maior movimentação econômica para o território e aumentando o fenômeno de ocupação do interior e áreas litorâneas.
              </Typography>
              <Typography>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Também com base em Assis e Sampaio, 2012: 
              </Typography> 
              <Typography>
                <span style={styles.quotes}>
                <FormatQuoteIcon sx={styles.quoteOpenIcon} />
                <span> 
                  Essa expansão ocorreu, de um lado, com as ocupações nas margens dos rios e dos valles do Salgado, Acaraú, Banabuiú, Coreaú, Mundaú e, principalmente, Jaguaribe, por boiadeiros-boiadas, e de outro, por motivos de uma tardia cartografia colonial, elaborada apenas nos últimos anos das colonizações portuguesas no Ceará da América. Poderíamos dizer ainda que tanto a ocupação tardia (somente no século XVIII) como a cartografia demorada têm relações diretas com as resistências das apropriações indígenas, das sociedades pré-coloniais, naquilo que se compreende como Ceará”.
                </span>
                <FormatQuoteIcon sx={styles.quoteCloseIcon} />
                </span>
              </Typography>
              <Typography>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A ocupação do território cearense além da região do Jaguaribe, devido a produção e transporte da carne de charque, passa também por localidades como as serras do Araripe, Apodi e Ibiapada. A invasão portuguesa em direção ao interior é marcada por conflitos com o envolvimento da própria Coroa portuguesa, jesuítas e indígenas, estes últimos foram cada vez mais adentrando ao sertão local como forma de sobrevivência, buscando fugir do genocídio e etnocídio português.
              </Typography>
          <Box sx={styles.text2}>
            <Box>
              <Typography>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A região da Ibiapaba foi povoada inicialmente por grupos indígenas que viveram naquela região, alguns chegaram até ali em rota de resistência as invasões europeias. Entre os presentes na região, podemos perceber os Tabajaras, Camocins, Anacés e Ararius do grupo Tapuia. Uma das primeiras expedições de invasão da região ocorreu a partir da Paraíba, de ondem partiram três caravelas portuguesas que aportaram na foz do Rio Jaguaribe, dali seguiram até a Serra de Ibiapaba onde se depararam com a presença francesa na região. Os portugueses liderados por Pero Coelho de Sousa conseguiram tomar o controle da região, mas falharam em expandir a sua ocupação até o Maranhão.
              </Typography>
              <Typography>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;No começo do século XVII, em 1608, é notada a presença dos jesuítas na região da Serra de Ibiapaba, liderados pelo padre Francisco Pinto, que acabou morto a partir do levante dos indígenas, a sua morte foi retratada na obra do pintor Michel Cnobbaert, em 1667. Segundo Yan Vasconcelos, 2005:
                <span style={styles.quotes}>
                <FormatQuoteIcon sx={styles.quoteOpenIcon} />
                <span>
                 Em 1656, Padre António Vieira visita pessoalmente a serra e instaura, com auxílio dos Padres Pedro de Pedrosa e António Ribeiro, a Missão de Ibiapaba. Entretanto, a missão falha em estabelecer bases sólidas para um aldeamento. Tal fato, só viria acontecer na virada para o século XVIII, com a fundação da Aldeia da Ibiapaba pelos Padres Ascenso Gago e Manuel Pedroso.
                </span>
                <FormatQuoteIcon sx={styles.quoteCloseIcon} />
                </span>
              </Typography>
              <Typography>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Com a criação da aldeia não houve interrupção das incursões da Companhia de Jesus que buscava cada vez mais cooptar indígenas por toda a região serrana. Isso fez com que houvesse um crescimento populacional na região, mas as custas da exploração e destruição de muitas comunidades indígenas. Segundo Leite (1943) Apud Yan Vasconcelos, 2005: 
                <span style={styles.quotes}>
                <FormatQuoteIcon sx={styles.quoteOpenIcon} />
                <span>
                  transcreve um relatório de João de Brewer, padre visitador que esteve pela Aldeia de Ibiapaba em 1756. Nele o 
                  religioso contabilizava a existência de 6.106 indígenas, em um total de 1000 casais, sendo grande parte oriundo 
                  da nação Tabajara. Domingos do Loreto Couto, cronista e religioso pernambucano, em sua obra “Desaggravos do 
                  Brasil e Glória de Pernambuco, ia além e afirmava que na Aldeia de Ibiapaba chegaram a viver mais de dez mil 
                  pessoas, sendo elas oriundas de quatro nações diferentes, tendo como as principais os Tabajaras e os Topez 
                  (Couto, 1994 Apud Vasconcelos, 2005)”.
                </span>
                <FormatQuoteIcon sx={styles.quoteCloseIcon} />
                </span>
              </Typography>
            </Box>
            { <img src={images.image1} />}
          </Box>
              <Typography>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A maior parte da economia da região girava em torno do gado. Em 1759, o território passou de aldeia à vila. Durante o processo de catequização jesuítica, foi construída a Igreja de Nossa Senhora da Assunção, em 1700. No ano da elevação ao status de vila os jesuítas foram expulsos da região, com a ordem geral de Marquês de Pombal para retirá-los do Brasil e a igreja passou a ficar sob jurisdição dos padres diocesanos. Em 7 de julho de 1759 foi erguida a paróquia da agora conhecida como Vila Viçosa Real.
              </Typography>
          <Box sx={styles.text1}>
            <Box>
              <Typography>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A partir de Vasconcelos, 2005, temos a seguinte descrição inicial de como era a organização espacial da igreja: 
                <span style={styles.quotes}>
                <FormatQuoteIcon sx={styles.quoteOpenIcon} />
                <span>
                  Ao centro se encontrava a igreja em honra a Nossa Senhora da Assunção e uma grande praça em forma de quadra. Vizinho à igreja, erigiu-se a residência dos padres, construída em madeira e barro, e coberta com folhas de palmeira, material ricamente encontrado na serra. 
                </span>
                <FormatQuoteIcon sx={styles.quoteCloseIcon} />
                </span>
              E segundo o relato do Mestre Piloto Manuel Róis dos Santos: 
              <span style={styles.quotes}>
                <FormatQuoteIcon sx={styles.quoteOpenIcon} />
                <span>
                  a igreja da aldeia da Ibiapaba se encontrava localizada em uma planície no alto da serra, logo ao lado se localizava a residência dos missionários e a sua frente havia erigido um cruzeiro. Bordeando a igreja e o grande largo da praça, se encontrava três carreiras de casas ou cabanas feitas ou cobertas de palha, onde habitavam indígenas das etnias tabajaras, anacés, cariús e coaçus (Studart, 2004 Apud Vasconcelos, 2005).
              </span>
                <FormatQuoteIcon sx={styles.quoteCloseIcon} />
                </span>
              </Typography>
              <Typography>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Segundo o IPHAN, A Igreja de Nossa Senhora da Assunção é 
                <span style={styles.quotes}>
                <FormatQuoteIcon sx={styles.quoteOpenIcon} />
                <span>
                  a mais antiga do Estado, construída pelos jesuítas, entre 1691 e 1700. Na época, os jesuítas catequizavam os índios Tabajara e, para atraí-los à fé católica, esculpiram a imagem de Nossa Senhora da Assunção à imagem e semelhança das mulheres daquela etnia. Ao longo dos anos, com a mudança de párocos, a igreja - em estilo barroco e fachada eclética - foi sendo reformada e descaracterizada, perdeu o altar do coral, e houve a inclusão de cerâmicas em seus interior e pedras na fachada”. (IPHAN, 2014). E a imagem de Nossa Senhora da Assunção “Retirada da igreja, pode ser vista no Museu Dom José, em Sobral. Os painéis do teto, que representavam os sete pecados capitais, desabaram em 1917, restando apenas o do altar-mor. (IPHAN, 2014).
                </span>
                <FormatQuoteIcon sx={styles.quoteCloseIcon} />
                </span>
              </Typography>
            </Box>
            { <img src={images.image4} />}
          </Box>
              <Typography>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A primeira versão da igreja, pensada desde a segunda metade do século XVII e construída nos anos finais do mesmo século, possuía uma porta e duas janelas, juntamente com a torre do sino, contendo um estilo predominantemente barroco, percebido através das doze telas no teto da igreja que retratam a presença dos jesuítas na região. A Igreja de Nossa Senhora da Assunção foi erguida a mando da Companhia de Jesus como uma forma de possuir um espaço que abrigasse os indígenas da região no processo de catequização. Para além disso, o espaço também pode ser pensado como um exemplo de sincretismo religioso e de resistência, dada a imagem de Nossa Senhora da Assunção semelhante aos nativos da Serra de Ibiapaba e os túneis subterrâneos que aparecem em alguns relatos como espaços de fuga para possíveis invasões.
              </Typography>
          <Box sx={styles.text2}>
            <Box>
              <Typography>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;No decorrer dos séculos XIX e XX, o templo religioso passou por algumas reformas que alteraram a sua estrutura original, trazendo o piso de cerâmica, as colunas laterais e a reestruturação interna devido a desabamento. José Liberal de Castro foi o responsável por produzir o parecer sobre o tombamento federal do trecho urbano de Viçosa do Ceará, nele podemos encontrar o seguinte relato: 
                <span style={styles.quotes}>
                <FormatQuoteIcon sx={styles.quoteOpenIcon} />
                <span>
                  O pedido de tombamento revela o intuito de preservar o quadro paisagístico que envolve a Igreja Matriz de Nossa Senhora da Assunção, tombada por este Conselho Consultivo em 16 de maio de 2002. De certo modo, atende, e com maior amplitude, à sugestão do próprio relator do presente processo, formulada quando pleiteou o tombamento da mencionada igreja matriz.
                </span>
                <FormatQuoteIcon sx={styles.quoteCloseIcon} />
                </span>
              </Typography>
              <Typography>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Também no documento de parecer produzido por Liberal de Castro podemos observar as razões para o tombamento, que segundo ele seriam: 
                <span style={styles.quotes}>
                <FormatQuoteIcon sx={styles.quoteOpenIcon} />
                <span>
                “Algumas motivações e justificativas contidas no processo nem sempre encontraram a concordância do relator. Há afirmações de caráter histórico de difícil comprovação, embora expressas de modo categórico. Constituem matéria discutível, proporcionando dúvidas, muitas, aliás, já levantadas pelo relator, quando solicitou o tombamento da Igreja Matriz da cidade. Enfim, suposições tomadas como realidade. Relevada, todavia, a opinião pessoal do relator sobre certos argumentos expostos no processo, por aquelas e por outras razões, o tombamento justifica-se fundamentalmente como um meio de realçar a cidade, em si, dado o peso de sua significação histórica e urbana, embora o atual acervo arquitetônico viçosense pouco ou nada tenha a ver objetivamente com as fases distantes do seu passado (salvo em reduzidas partes da Igreja Matriz). Mais consentâneo seria se solicitasse o tombamento recorrendo ao apoio das normas, cartas e recomendações atinentes ao assunto, propostas em encontros patrocinados pela UNESCO, pela OEA e pelo ICOMOS (International Council on Monuments and Sites), entidades internacionais das quais o Brasil participa como membro fundador. Deste modo, ao ver do relator, o primeiro e ponderável argumento em favor do tombamento da área definida pela 4. Superintendência Regional do IPHAN repousaria na necessidade de se preservar um quadro de valorização da Igreja Matriz de Nossa Senhora da Assunção," monumento artístico de expressão nacional”.
                </span>
                <FormatQuoteIcon sx={styles.quoteCloseIcon} />
                </span>
              </Typography>
            </Box>
            {<img src={images.image3} /> }
          </Box>
              <Typography>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Em matéria do Diário do Nordeste, em 2005, também foi destacado o tombamento da área urbana da cidade: 
                <span style={styles.quotes}>
                <FormatQuoteIcon sx={styles.quoteOpenIcon} />
                <span>
                  foi declarada oficialmente patrimônio histórico nacional pelo Iphan, no dia 14 de agosto de 2003, por reunir um conjunto de antigas edificações de comprovado valor arquitetônico e em bom estado de conservação. Hoje está definido o perímetro de proteção com 72 casarões, principalmente em torno da Praça Clóvis Beviláqua, ruas Lamartine Nogueira e Silva Jardim. No sítio histórico estão casarões de estilo colonial, o Teatro D. Pedro II, a Casa Paroquial, Gabinete Viçosense de Leitura, Solar da família Pinho Pessoa, Solar da Marcela e a Igreja de Nossa Senhora Assunção. No dia 9 de dezembro de 2004 a igreja foi interditada para execução de um trabalho de prospecção, objetivando a elaboração do projeto de restauro com o resgate dos traços originais.
                </span>
                <FormatQuoteIcon sx={styles.quoteCloseIcon} />
                </span>
              </Typography>
          <Box sx={styles.text1}>
            <Box>
              <Typography>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;As reformas feitas em todo o percurso histórico buscaram revitalizar e manter as características ecléticas do espaço, que conta com preservações, mas também muita destruição dos processos originais, não somente pelo longo tempo das construções, mas por descaso e busca de uma falsa modernização. A história de Viçosa e dos seus espaços passa diretamente pela luta e resistência dos povos indígenas e dos massacres que recaem sobre essa população desde o período colonial.
              </Typography>
            </Box>
          </Box>
      </Box>
    </Box>
  );
};
