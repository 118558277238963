export const useStyles = () => ({
  container: {
    backgroundColor: "#fff",
    borderRadius: "5px",
    boxShadow: "2px 2px 5px #000000aa",
    padding: "1rem",
  },
  card: {
    width: "464px",
    height: "261px",
    display: "flex",
    position: "relative",
    justifyContent: "center",
    alignItems: "center",
    margin: "5px",
    cursor: "pointer",
    "& p": {
      transition: "ease-in-out 0.5s",
    },
    "&:hover p": {
      opacity: 1,
    },
  },
  image: {
    height: "100%",
    width: "100%",
    backgroundSize: "464px 261px",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    transition: "ease-in-out 0.5s",
    "&:hover": {
      filter: "brightness(0.45)",
      backgroundSize: "512px 288px",
    },
  },
  title: {
    position: "absolute",
    bottom: "15px",
    left: "15px",
    textAlign: "left",
    width: "90%",
    color: "#FFFFFF",
    pointerEvents: "none",
    fontWeight: "600 !important",
    fontSize: "24px !important",
    padding: "8px",
    ["@media (min-width:900px)"]: {
      opacity: 0,
      maxWidth: "320px",
      bottom: "unset",
      left: "unset",
      textAlign: "center",
    },
  },
});
