import { createContext, useState, useCallback } from "react";

interface IMenuVisibilityContext {
  isMenuVisible: boolean;
  toggleMenuVisibility: () => void;
}

export const MenuVisibilityContext = createContext({} as IMenuVisibilityContext);

type Props = {
    children?: React.ReactNode;
}
const MenuVisibilityProvider: React.FC<Props> = ({ children }) => {
  const [isMenuVisible, setIsMenuVisible] = useState<boolean>(true);

  const toggleMenuVisibility = useCallback(() => {
    setIsMenuVisible((prevState) => !prevState);
  }, []);

  return (
    <MenuVisibilityContext.Provider
      value={{ isMenuVisible, toggleMenuVisibility }}
    >
      {children}
    </MenuVisibilityContext.Provider>
  );
};

export default MenuVisibilityProvider;
