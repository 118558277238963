import image1 from "./equipe1.png";
import image2 from "./equipe2.png";
import image3 from "./equipe3.png";
import image4 from "./equipe4.png";

export const images_team = {
    image1,
    image2,
    image3,
    image4
}