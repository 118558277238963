import React, {useState} from "react";
import { Box, Divider, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useStyles } from "./CardPersonal.style";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import VisibilitySensor from 'react-visibility-sensor';

interface CardPersona {
  name: string;
  cargo: string;
  image: string;
  lattes: string;
}
export const CardPersonal: React.FC<CardPersona> = ({
  name,
  cargo,
  image,
  lattes,
}) => {
  const styles = useStyles();

  const handleClick = () => {
    window.open(lattes, "_blank");
  };

  const [isVisible, setIsVisible] = useState(false);  
  const onVisibilityChange = (isVisible: boolean) => {
    setIsVisible(isVisible);
  };  
  const mdDown = useMediaQuery(useTheme().breakpoints.down("md"));

  return (
    <Card
      onClick={handleClick}
      sx={{
        cursor : "pointer",
        width: 215,
        backgroundColor: "#EAE6E4",
        boxShadow: 'none'
      }}
    >
      <Box sx={styles.div}>        
        <VisibilitySensor onChange={onVisibilityChange}>
          <CardMedia
            component="img"
            alt="pessoa"
            sx={styles.image}
            image={image}
          />
        </VisibilitySensor>        
      </Box>
      <Divider/>
      <Box sx={styles.content}>
        <CardContent sx={{ height: "100%" }}>
          <Typography
            sx={styles.name}
            gutterBottom
            variant="h6"
            component="div"
          >
            {name}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {cargo}
          </Typography>
        </CardContent>
      </Box>
    </Card>
  );
};
