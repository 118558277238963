import { Footer } from "../footer";
import { MainMenu } from "../menu-main";


export const DefaultLayout : React.FC<any> = ({children}) => {
    return(
        <>
            <MainMenu shadow={false}/>
                {children}
            <Footer/>
        </>
    )
};