import { Box } from "@mui/material";
import { MiniMenu } from "shared/components/menu-monument/MiniMenu";
import { TourVirtual } from "shared/components/tour-virtual";
import { History } from "./components";
import { useStyles } from "./TJA.style";
import homeGallery from "./TJAgallery";
import { CardHowToFind } from "shared/components/card-how-to-find";
import { Gallery } from "shared/components/gallery/Gallery";

export const TJA: React.FC = () => {
  const styles = useStyles();

  return (
    <Box sx={styles.container}>
      <Box sx={styles.tour}>
        <TourVirtual src="https://repositoriodocumentace.online/theatro-jose-de-alencar/index.htm" />
        <Box sx={styles.miniMenu}>
          <MiniMenu
            title="Theatro José de Alencar"
            history={<History />}
            gallery={<Gallery images={homeGallery} />}
            info={
              <CardHowToFind
                address={{
                  name: "R. Liberato Barroso, 525 - Centro, Fortaleza - CE, 60030-160",
                  link: "https://goo.gl/maps/kvwJ8MNMdz8fbx4h8",
                }}
                guidedTour="Agendamento: (85) 3101-2586"
                accessibility="Possui alternativas de acessibilidade: Incluindo rampa de acesso, sinalização tátil,
                sanitário e bebedouros adaptados"
                contacts={{
                  phoneNumber: "(85) 3101-2586",
                  whatsApp: "(85) 98206-9799",
                  email: "tja.comunica@gmail.com",
                }}
                socialMedia={{
                  Instagram:
                    "https://www.instagram.com/tja.theatrojosedealencar/",
                  Facebook:
                    "https://www.facebook.com/theatrojosedealencar/?locale2=en_GB&paipv=0&eav=AfYl-DAZPskirl-cWRHFCAkaexc4Cdcd8-RhPeXGPJwGVuZ7kTm6_kxRSuo-pn58U0c&_rdr",
                  YouTube: "https://www.youtube.com/theatrojosedealencar",
                }}
              />
            }
          />
        </Box>
      </Box>
    </Box>
  );
};
