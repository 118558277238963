import image1 from "./tja.jpg";
import image2 from "./sobrado.jpg";
import image3 from "./bece.jpg";
import image4 from "./cineSaoLuiz.jpg";
import image6 from "./escolaGastronomia.jpg";
import image7 from "./mis.jpg";
import image8 from "./thomazPompeu.jpg";
import image9 from "./pinacoteca.jpg"
import image10 from "./igreja-nossa-senhora.JPG"



export const images = {
    image1,
    image2,
    image3,
    image4,
    image6,
    image7,
    image8,
    image9,
    image10,
}