import img01 from "assets/img/history/sobrado/img01.jpg";
import img02 from "assets/img/history/sobrado/img02.jpg";
import img03 from "assets/img/history/sobrado/img03.jpg";
import img04 from "assets/img/history/sobrado/img04.jpg";
import img06 from "assets/img/history/sobrado/img06.jpg";

const homeGallery = [
    img01,
    img02,
    img03,
    img04,
    img06,
]

export default homeGallery;