import img01 from "assets/img/gallery/MIS/img01.jpg";
import img02 from "assets/img/gallery/MIS/img02.jpg";
import img03 from "assets/img/gallery/MIS/img03.jpg";
import img04 from "assets/img/gallery/MIS/img04.jpg";
import img05 from "assets/img/gallery/MIS/img05.jpg";
import img06 from "assets/img/gallery/MIS/img06.jpg";
import img07 from "assets/img/gallery/MIS/img07.jpg";
import img08 from "assets/img/gallery/MIS/img08.jpg";
import img09 from "assets/img/gallery/MIS/img09.jpg";
import img10 from "assets/img/gallery/MIS/img10.jpg";
import img11 from "assets/img/gallery/MIS/img11.jpg";
import img12 from "assets/img/gallery/MIS/img12.jpg";
import img13 from "assets/img/gallery/MIS/img13.jpg";

const homeGallery = [
  img01,
  img02,
  img03,
  img04,
  img05,
  img06,
  img07,
  img08,
  img09,
  img10,
  img11,
  img12,
  img13,
];

export default homeGallery;
