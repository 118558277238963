import img02 from "assets/img/gallery/EAOTPS/img02.jpg";
import img03 from "assets/img/gallery/EAOTPS/img03.jpg";
import img04 from "assets/img/gallery/EAOTPS/img04.jpg";
import img05 from "assets/img/gallery/EAOTPS/img05.jpg";
import img06 from "assets/img/gallery/EAOTPS/img06.jpg";
import img08 from "assets/img/gallery/EAOTPS/img08.jpg";
import img09 from "assets/img/gallery/EAOTPS/img09.jpg";
import img11 from "assets/img/gallery/EAOTPS/img11.jpg";
import img12 from "assets/img/gallery/EAOTPS/img12.jpg";
import img13 from "assets/img/gallery/EAOTPS/img13.jpg";
import img14 from "assets/img/gallery/EAOTPS/img14.jpg";
import img15 from "assets/img/gallery/EAOTPS/img15.jpg";
import img17 from "assets/img/gallery/EAOTPS/img17.jpg";
import img18 from "assets/img/gallery/EAOTPS/img18.jpg";
import img19 from "assets/img/gallery/EAOTPS/img19.jpg";
import img20 from "assets/img/gallery/EAOTPS/img20.jpg";
import img21 from "assets/img/gallery/EAOTPS/img21.jpg";

const homeGallery = [
    img02,
    img03,
    img04,
    img05,
    img06,
    img08,
    img09,
    img11,
    img12,
    img13,
    img14,
    img15,
    img17,
    img18,
    img19,
    img20,
    img21,
]
export default homeGallery;