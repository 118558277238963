// import { makeStyles } from "@mui/styles";

export const useStyles = () => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    flexWrap: "wrap",
    margin: "0px",
    "& p": {
      textAlign: "justify",
    },
  },
  text1: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignContent: "center",
    textAlign: "justify",
    flexWrap: "wrap",
    "& div": {
      width: "100%",
      padding: "5px",
      alignSelf: "center",
    },
    "& img": {
      width: "100%",
      height: "50%",
      padding: "5px",
      // alignSelf: "center",
    },
  },
  text2: {
    width: "100%",
    display: "flex",
    flexDirection: "row-reverse",
    justifyContent: "center",
    alignContent: "center",
    textAlign: "justify",
    flexWrap: "wrap",
    "& div": {
      width: "100%",
      padding: "5px",
      alignSelf: "center",
    },
    "& img": {
      width: "100%",
      height: "50%",
      padding: "5px",
      alignSelf: "center",
    },
  },
  quotes: {
    fontStyle: "oblique",
  },
  quoteOpenIcon: {
    transform: "scale(-1,-1)",
    stroke: "white",
    color: "black",
  },
  quoteCloseIcon: {
    stroke: "black",
    color: "white",
  },
});
