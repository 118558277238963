import React, { useState } from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Box,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useStyles } from "./MenuVertical.style";
import { useNavigate } from "react-router";
import { useTheme } from "@mui/material/styles";

export const MenuVertical: React.FC = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const navigate = useNavigate();
  const styles = useStyles();
  const theme = useTheme();

  return (
    <Box sx={{ ...styles.main, background: "transparent" }}>
      <Drawer open={openDrawer} onClose={() => setOpenDrawer(false)}>
        <List
          component="ul"
          sx={{ ...styles.item, background: theme.palette.primary.main }}
        >
          <ListItem
            component="li"
            onClick={() => {
              setOpenDrawer(false);
              navigate("/#");
            }}
          >
            <ListItemText>
              <Typography component="span">início</Typography>
            </ListItemText>
          </ListItem>
          <ListItem
            component="li"
            onClick={() => {
              setOpenDrawer(false);
              navigate("/monumentlist");
            }}
          >
            <ListItemText>
              <Typography component="span">acervo</Typography>
            </ListItemText>
          </ListItem>
          <ListItem
            component="li"
            onClick={() => {
              setOpenDrawer(false);
              navigate("/news");
            }}
          >
            <ListItemText>
              <Typography component="span">notícias</Typography>
            </ListItemText>
          </ListItem>
          <ListItem
            component="li"
            onClick={() => {
              setOpenDrawer(false);
              navigate("/about-us");
            }}
          >
            <ListItemText>
              <Typography component="span">sobre</Typography>
            </ListItemText>
          </ListItem>
        </List>
      </Drawer>
      <IconButton onClick={() => setOpenDrawer(!openDrawer)}>
        <MenuIcon />
      </IconButton>
    </Box>
  );
};
