export const useStyles = () => ({
  container: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: "1rem",
    ["@media (max-width:900px)"]: {
      gridTemplateColumns: "1fr",
    },
    "& > div": {
      maxWidth: "100% !important",
    },
  },
  texts: {
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
    flexDirection: "column",
    padding: "120px 0px",
    // backgroundColor: "#5A2E85",
    // color: "#FFF",
  },
  title: {
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
    margin: "15px",
    "& p": {
      fontSize: "40px",
      padding: "0px 5px",
      fontWeight: "lighter",
    },
  },
  subtitle: {
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
    alignSelf: "center",
    maxWidth: "600px",
    margin: "0px 10%",
    "& p": {
      fontSize: "20px",
      fontWeight: "lighter",
      textAlign: "center",
    },
  },
});
