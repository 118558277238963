import { Box, Typography } from "@mui/material";
import { SlideIn } from "shared/components/slideIn";
import { useStyles } from "./history.style";
import { images } from "assets/img/history/bece";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";

export const History: React.FC = () => {
    const styles = useStyles();

    return (
        <Box sx={styles.container}>
                    <Box sx={styles.text1}>
                        <Box>
                            <Typography>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Desde a antiguidade os seres humanos
                                possuem experiências com as bibliotecas. Do grego, biblíon, livro e teke, que
                                pode significar depósito ou caixa, é a forma de identificar o espaço utilizado
                                para depositarmos livros e obras escritas de forma geral. Os primeiros locais com
                                essa característica são datados desde antes de Cristo, possuindo como principal aspecto
                                a concentração de conhecimento e informações, além dos traços culturais e sociais amplos de
                                períodos da história humana.
                            </Typography>
                            <Typography>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Algumas bibliotecas se tornaram muito famosas devido
                                ao seu tamanho, quantidade de obras e também o seu poder social, por exemplo, a Biblioteca
                                Real de Alexandria, a Biblioteca de Roma, com obras gregas e em latim, além da Biblioteca de Nívine,
                                no Iraque, que ficaram conhecidas para além do seu tempo. Para a compreensão da importância desses espaços, 
                                é necessária a observação para muito além de um depósito, mas como um grande centro de poder.
                            </Typography>
                            <Typography>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Outra característica humana que podemos observar desde a antiguidade
                                é a dificuldade do acesso à informação e a limitação da formação intelectual como mecanismo facilitador
                                do domínio de determinadas categorias sociais. 
                            </Typography>
                        </Box>
                        <img src={images.image1} />
                    </Box>
                <Typography>
                Algumas bibliotecas foram erguidas com a intenção de ampliar 
                                a informação do seu povo, como exemplo, a Biblioteca Pública do Ceará, mas nem sempre cumpriram tal objetivo, 
                                como veremos. Por isso, é importante analisar o poder que envolve essas instituições.
                </Typography>
                <Typography>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Na Idade Média, vimos às bibliotecas serem controladas pela Igreja com o 
                    intuito de proibir o acesso a qualquer forma de conhecimento que não fosse o permitidaoo pela própria instituição, 
                    mais adiante, no Renascimento e Iluminismo, pudemos observar uma tentativa de maior disseminação do conhecimento 
                    científico, com a produção da enciclopédia e outras
                     obras filosóficas.
                </Typography>
                <Typography>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Voltando para o Ceará, a construção da Biblioteca Pública está datada no 
                    contexto do século XIX, período de muitas transformações a nível mundial em aspectos intelectuais, sociais e 
                    culturais. Fortaleza passava por uma série de mudanças nos mesmos aspectos, buscando tomar como espelho o que 
                    acontecia na Europa, momento que conhecemos como Belle Époque, com a modificação urbana da cidade, novos hábitos 
                    e costumes e uma intensa produção intelectual, um dispositivo urbano era necessário: A Biblioteca.

                </Typography>
                <Typography>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A movimentação intelectual no Ceará é forte desde as primeiras décadas do 
                    século XIX, temos uma imprensa muito ativa, com jornais católicos e outros de cunho liberal, além das diversas 
                    agremiações e grupos intelectuais além dos colégios locais. O acesso intelectual era limitado, dado o alto índice 
                    de analfabetismo, principalmente em Fortaleza.

                </Typography>
                    <Box sx={styles.text2}>
                        <Box>
                            <Typography>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;É neste cenário que, em 1848, surge pela primeira vez à ideia de 
                                construção da Biblioteca Pública do Ceará, um dos principais dispositivos culturais e intelectuais não 
                                só de Fortaleza, mas do Estado. O plano de construção teve início durante o governo do Desembargador 
                                Federico Pamplona, mas só foi efetivado com o pedido do Presidente Conselheiro Barão Homem de Melo, em 
                                1865. A Biblioteca é inaugurada em 1867, com o vernaculista e pedagogo Dr. José Barcelos, primeiro 
                                diretor do espaço.

                            </Typography>
                            <Typography>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A Biblioteca Provincial, como ficou inicialmente conhecida, foi 
                                inaugurada no dia 25 de março de 1867 e também cumpria a função de Arquivo Público, sob o governo do 
                                Tenente Coronel Engenheiro João de Sousa Melo e Alvim, então presidente da Província. A obra custou o 
                                total de vinte e três contos, setecentos e vinte e oito mil, seiscentos e doze réis. O prédio havia 
                                sido recém-construído junto à Escola Pedagógica, na Praça Marques de Herval, atual Praça José de Alencar
                                . O acervo inicial era de 1730 volumes, sendo 614 adquiridos pelo governo e 1116 por doações de 
                                particulares.

                            </Typography>
                        </Box>
                        <img src={images.image2} />
                    </Box>
                    <Typography>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A Biblioteca é criada anteriormente a Academia Cearense de Letras, 
                                além disso, apenas Rio de Janeiro, Pernambuco e Bahia possuíam Bibliotecas Públicas naquele momento, o 
                                que demonstra um possível interesse do governo no que diz respeito à formação intelectual da população. 
                                Na segunda metade do século XIX, as taxas de alfabetização vinham subindo, até aqui, apenas pessoas com 
                                alta condição financeira possuíam acesso a uma boa educação e a muitos livros que eram produzidos no 
                                país ou importados.

                            </Typography>
                {/* </SlideIn> */}
                {/* <SlideIn movement={200}> */}
                    <Box sx={styles.text1}>
                        <Box>
                            
                            <Typography>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A Biblioteca Pública do Ceará passou por alguns endereços, apenas 
                                em 1975, no dia 6 de fevereiro, durante o governo do presidente Ernesto Geisel, na ditadura civil-militar,
                                foi inaugurado o atual prédio do espaço. Contando com 2272 metros quadrados, distribuídos em cinco 
                                pavimentos, na Avenida Presidente Castelo Branco.

                            </Typography>
                            <Typography>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Durante o século XX, tivemos no Brasil, mais especificamente em 
                                São Paulo, um dos eventos mais importantes para a arte nacional, a Semana de Arte Moderna, em 1922. 
                                A defesa da arte brasileira propagada durante a semana já havia sido antecipada enquanto ideia aqui 
                                mesmo no Ceará, com a Padaria Espiritual. Fato é que as ideias defendidas pelos padeiros e mais tarde 
                                na Semana de Arte Moderna acabaram por influenciar um dos mais importantes pavimentos da Biblioteca 
                                Pública, o setor Ceará. Esse espaço é destinado a obras ligadas a cultura e tradições cearenses, 
                                valorizando a identidade local. Nele estão produções sobre o Ceará, obras feitas por cearenses e 
                                também editadas aqui.
                            </Typography>
                        </Box>
                        <img src={images.image3} />
                    </Box>
                {/* </SlideIn> */}
                <Typography>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Os membros da Padaria Espiritual, os padeiros, chegaram inclusive a fazer 
                    críticas ao espaço da Biblioteca. A indagação dos membros da Padaria foi ao espaço da Biblioteca Pública, 
                    o horário de funcionamento não permitia a presença de trabalhadores do comércio:

                </Typography>
                {/* <SlideIn movement={200}> */}
                    <Box sx={styles.text2}>
                        <Box>
                            <Typography>
                              <span style={styles.quotes}>
                                <FormatQuoteIcon sx={styles.quoteOpenIcon} />
                                <span>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Bibliottecha Publica
                                Concitamos o cidadão Governador do Estado a dar execução á petição que lhe dirigimos a respeito do 
                                horario da Bibliotecha Publica.
                                Este estabelecimento abre-se ás 10 horas da manhã e fecha-se ás 3 da tarde, como qualquer outra 
                                repartição.
                                Quem transpôs o humbral da Bibliotecha, apezar do grande desejo e necessidade que tem de fazel-o, 
                                porque está aferrado ás suas obrigações justaente ao tempo em que está ella aberta ao publico.
                                Vamos, cidadão Governador, seja rasoavel, faça isto: mande abrir a Bibliotecha das 7 ás 9 da manhã e 
                                das 6 ás 9 da noute, e garantimos que ella será frequentada por muita gente que, á falta de occupação 
                                melhor, vai jogar bilhar na Maison e dominó no Java.
                                Faça o nosso pedido; sim? (JUREMA. Moacyr/ Antônio Sales. O Pão... Da Padaria Espiritual. Anno I. 
                                Número 1. 10 de Julho de 1892. P. 4).
                                </span>
                                <FormatQuoteIcon sx={styles.quoteCloseIcon} />
                                </span>
                            </Typography>
                            <Typography>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;O trecho da Padaria Espiritual nos leva a questionar alguns 
                                fatores sobre a gestão da cidade e sobre o uso de espaços, observando: 
                            </Typography>
                            <Typography>
                                1. Somado ao fato da alta taxa 
                                de analfabetismo, o fato da Biblioteca Pública não funcionar em horários de mais fácil acesso a 
                                população, até mesmo aos profissionais liberais e detentores de alguma forma de capital intelectual, 
                                acabaria limitando o acesso ao conhecimento contido nas obras ali estabelecidas. 
                            </Typography>
                        </Box>
                        <img src={images.image4} />
                    </Box>
                {/* </SlideIn> */}
                <Typography>
                    2. A Biblioteca Pública enquanto um espaço de debate intelectual, a construção de um espaço de sociabilidade, pela 
                    citação, uma localização com maior identidade para os anseios de civilização e crescimento intelectual, 
                    mas o fator horário de funcionamento, não lhes permitia tal feito, lhes sobravam os jogos de bilhar no 
                    Maison e dominó no Java. Foram feitas várias críticas ao governo sobre o horário de funcionamento, 
                    o que não trouxe resultado e levou vários intelectuais a frequentarem outros espaços da cidade.
                </Typography>
                <Typography>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;No dia 22 de maio de 1978, pelo decreto 12768, o então governador Waldemar 
                    Alcântara altera o nome da Biblioteca Pública do Estado para Biblioteca Pública Governador Menezes Pimentel, em 
                    homenagem ao ex-governador Francisco de Menezes Pimentel. O espaço passou por grande reforma, sendo reinaugurado 
                    no dia 25 de março de 2002, com um novo acervo de 77561 obras e integrando o Centro Cultural Dragão do Mar de Arte 
                    e Cultura, criando um grande local com cinema, teatro, anfiteatro, museu e outros mecanismos culturais.
                </Typography>
                {/* <SlideIn movement={200}> */}
                    <Box sx={styles.text1}>
                        <Box>
                            <Typography>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;O acervo continuou crescendo, passando dos 95000 volumes, divididos 
                                em obras gerais, Ceará, periódicos, Braille, Infanto-juvenil, Referência, Iconografia, Multimídia e 
                                Obras Raras. A reforma iniciada em 1999 e concluída em 2002 buscou manter características arquitetônicas
                                 originais do espaço, mas ao mesmo tempo torná-lo mais moderno. Com as reformas e leis de incentivo à
                                  cultura, a Biblioteca passou a oferecer diversas atividades para a comunidade, como a aquisição de 
                                  mais obras raras, passando por Padaria Espiritual, José de Alencar, Rachel de Queiroz e outros 
                                  autores locais; a Biblioteca Virtual, informatização do acervo e revitalizações.

                            </Typography>
                            <Typography>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A última reforma da Biblioteca foi concluída com a sua 
                                reinauguração, no dia 12 de agosto de 2021, depois de sete anos fechada. O investimento do Governo do 
                                Estado na reforma e modernização da Biblioteca Pública, por meio da Secretaria da Cultura do Estado do 
                                Ceará, foi de R$ 21.671.697,79, sendo R$ 11.348.762,75  destinados à obra estrutural e 
                                R$ 10.322.935,04‬ à modernização, incluindo a aquisição de equipamentos, mobiliário, 
                                ambientação e acervo. Também foram investidos R$ 2.846.362,95‬ na obra de integração da 
                                Biblioteca com o Centro Dragão do Mar de Arte e Cultura, segundo apuração de Lauriberto Carneiro 
                                Braga.

                            </Typography>
                        </Box>
                        <img src={images.image5} />
                    </Box>
                {/* </SlideIn> */}
                <Typography>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A Biblioteca retomou todas as atividades, como empréstimos e consultas de 
                    obras, além da consulta virtual e visita guiada ao espaço, que passou a ser reconhecido oficialmente 
                    como BECE – Biblioteca Pública Estadual do Ceará. Mesmo com a internet e a difusão de obras em formato PDF, a 
                    Biblioteca é um espaço crucial para a memória local, contando com material raro e único, além de ofertar atividades 
                    culturais diversas, um espaço atrativo de diversas formas e manter vida a nossa memória, contribuindo para 
                    pesquisadores de todo o Brasil.

                </Typography>
        </Box>
    );
};
