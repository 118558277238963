import { Box } from "@mui/material";
import { useStyles } from "./GalleryItem.style";

interface GalleryItemProps {
  image: string;
  onClick: ()=>void;
}

export const GalleryItem: React.FC<GalleryItemProps> = ({ image, onClick }) => {
  const styles = useStyles();
  return (
    <Box onClick={onClick} sx={styles.root}>
      <img src={image} alt="" loading="lazy" />
    </Box>
  );
};
