import { Box } from "@mui/material";
import { MiniMenu } from "shared/components/menu-monument/MiniMenu";
import { TourVirtual } from "shared/components/tour-virtual";
import { History } from "./components";
import { useStyles } from "./SOBRADO.style";
import { Gallery } from "shared/components/gallery/Gallery";
import homeGallery from "./SOBRADOgallery";
import { CardHowToFind } from "shared/components/card-how-to-find";

export const SOBRADO: React.FC = () => {
  const styles = useStyles();

  return (
    <Box sx={styles.container}>
      <Box sx={styles.tour}>
        <TourVirtual src="https://repositoriodocumentace.online/sobrado-jose-lourenco/index.htm" />
        <Box sx={styles.miniMenu}>
          <MiniMenu
            title = "Sobrado Dr. José Lourenço"
            history={<History />}
            gallery={<Gallery images={homeGallery} />}
            info={
              <CardHowToFind
                address={{
                  name: " Av. Major Facundo 154, Centro, Fortaleza - CE, 60025-100",
                  link: "https://goo.gl/maps/Wqryr6J7cCJqtJYV6",
                }}
                guidedTour="Preencher Formulário diponível no Instagram do Sobrado Dr. José Lourenço."
                openingHours="Terça a sexta: 9h às 17h | Sábados: 9h às 14h"
                accessibility="Possui alternativas de acessibilidade: Elevador."
                contacts={{
                  phoneNumber: "(85) 3101-8826",
                  email: "sobrado.3107@gmail.com",
                }}
                socialMedia={{
                  Instagram:
                    "https://www.instagram.com/sobrado154/",
                  Facebook:
                    "https://m.facebook.com/SobradoJL/?locale2=pt_BR&__tn__=C-R",
                }}
              />
            }
          />
        </Box>
      </Box>
    </Box>
  );
};
