// import { makeStyles } from "@mui/styles";
import { images } from "assets/img/history/escola-gastronomia";

export const useStyles = () => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    flexWrap: "wrap",
    padding: "0px",
    margin: "0px",
    width: "100%",
    height: "100%",
  },
  tour: {
    width: "100%",
    height: "100%",
    backgroundImage: `url(${images.img01})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundAttachment: "fixed",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  miniMenu: {
    width: "100%",
    maxWidth: "1200px",
    minWidth: "300px",
    marginBottom: "30px",
  },
});