import { Box } from "@mui/material";
import { MiniMenu } from "shared/components/menu-monument/MiniMenu";
import { TourVirtual } from "shared/components/tour-virtual";
import { History } from "./components";
import { useStyles } from "./igreja.style";
import { Gallery } from "shared/components/gallery/Gallery";
import homeGallery from "./igreja-gallery";
import { CardHowToFind } from "shared/components/card-how-to-find";

export const IGREJA: React.FC = () => {
  const styles = useStyles();

  return (
    <Box sx={styles.container}>
      <Box sx={styles.tour}>
        <TourVirtual src="https://repositoriodocumentace.online/igreja-nossa-senhora-da-assuncao/index.htm" />
        <Box sx={styles.miniMenu}>
          <MiniMenu
            title="Igreja Nossa Senhora Da Assunção"
            history={<History />}
            gallery={<Gallery images={homeGallery} />}
            info={
              <CardHowToFind
                address={{
                  name: "Praça Clóvis Beviláqua, Centro, N° 589, Viçosa do Ceará - CE, 62300-000",
                  link: "https://maps.app.goo.gl/tUSkVC1KGmpm4yCA7",
                }}
                openingHours="Segunda à sexta: 08h - 17h | Sábado: 08h - 12h | Domingo: 06h - 11h"
                contacts={{
                  phoneNumber: "(88) 99342-4021",
                  email:"paroquiadevicosace@gmail.com"
                }}
                socialMedia={{
                  Instagram: "https://www.instagram.com/pnsavicosa/",
                  Facebook:"https://m.facebook.com/pnsavicosa",
                  YouTube:"https://www.youtube.com/c/Vi%C3%A7osaCat%C3%B3lica?sub_confirmation=1",
                }}
              />
            }
          />
        </Box>
      </Box>
    </Box>
  );
};
